import { Injectable } from '@angular/core';
import {
HttpRequest,
HttpHandler,
HttpEvent,
HttpInterceptor,
HttpErrorResponse,
HttpResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, map, delay, finalize, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { TokenService } from '../services/token.service'


@Injectable()

export class TokenAuthInterceptor implements HttpInterceptor {

constructor(private token: TokenService, private spinner: NgxSpinnerService,
private toastr: ToastrService) { }

intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
this.spinner.show();

const token = this.token._get_token();
const token_type = this.token._get_token_type();
// console.log("im interceptor", token)
if (token != null && !request.urlWithParams.endsWith(`nonuser/products`) ) {
request = request.clone({ headers: request.headers.set('Authorization', `${token_type} ${token}`) })
}
// else{
//     request = request.clone({headers:request.headers.set('access-control-allow-origin','*')})
// }

return next.handle(request).pipe(
delay(1000),
finalize(() => setTimeout(() => {
    this.spinner.hide()
}, 0)),
catchError((err: HttpErrorResponse) => {
    console.log("err",err.error.message);
    // debugger;
    if(err.error.message=="Unauthenticated."){
        setTimeout(() => {
            this.spinner.hide()
        }, 0)
    this.toastr.warning("Please login to continue");
    }else if(err.error?.message?.password_confirmation[0]=="The password confirmation and password must match."){
 
    this.toastr.warning("The password confirmation and password must match.");
    }
else{
    setTimeout(() => {
        this.spinner.hide()
    }, 0)
// this.toastr.warning(err.error.message? err.error.message: 'Something went wrong', 'Warning');
this.toastr.warning('Something went wrong', 'Warning');
}

return throwError(err);
})

);

}
}
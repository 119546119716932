import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { TokenService } from '../services/token.service'
declare var $;
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public token: TokenService, public router: Router) {}
  canActivate(): boolean {
    // this will be passed from the route config
    // on the data property
    const token = this.token._get_token();
    // decode the token to get its payload
    if (!token) {
        $('#myModal').modal('show');
        return false;
    }
    return true;
  }
}
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from "angular2-cookie/services/cookies.service";
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CategoriesComponent } from "src/app/customers/categories/categories.component";
import { ModalComponent } from 'src/app/modal/modal/modal.component';
import { ModalConfig } from 'src/app/shared/modal.config';
import { DataService } from "../../services/data.service";
import { TokenService } from "../../services/token.service";
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('600ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('600ms', style({ opacity: 0 }))
      ])
    ]
    )
  ],
  providers: [CategoriesComponent]
})
export class HeaderComponent implements OnInit, OnDestroy,DoCheck {


  public loginData: any = {};
  receiveItem: string
  fpass: any = {

  }
  @ViewChild('modal') private modalComponent: ModalComponent
  modalConfig: ModalConfig = {
    modalTitle: "Logout",
    dismissButtonLabel: "Cancel",
    closeButtonLabel: "Ok",

  }
  s: any;
  public foot_icon: Array<any> = [];

  async openModal() {


    await this.modalComponent.open()
    if (this.modalConfig.submitte) {
      localStorage.removeItem('profile_image') ;
      this.logout()
    }



  }
  @ViewChild("myNameElem") myNameElem: ElementRef;

  companyToggle: boolean;
  companyTog: boolean = false
  out_producrt_toggle: boolean = false
  gallary_toggle: boolean = false
  respose_toggle: boolean = false
  contact_toggle: boolean = false

  public remember: boolean = false;
  isloggedIn: boolean = false;
  username: string = '';
  menus: any = [];
  fieldTextType: boolean = false;
  submitted: boolean = false;
  hometoggle: boolean = false;
  hometoggle_1: boolean = false;
  hometoggle_2: boolean = false;
  hometoggle_3: boolean = false;
  hometoggle_4: boolean = false;

  isReadOnly: boolean = true;
  companyActive: boolean = false;
  ourProductActive: boolean = false;
  contactActive: boolean = false;
  resposibiltyActive: boolean = false;
  homeactive: boolean;
  galaryActive: boolean = false;
  awardActive: boolean = false;
  isVisible: boolean = true;
  currentRoute: any;
  routeData: any;
  catagoryName: any;
  menuName: string = "";
  wishListActive: boolean = false;
  urlName: any;
  wishDetailName: any;
  message: string;
  subscription: Subscription;

  getGalleryIconsSub$: Subscription;
  @ViewChild('el', { static: false })
  el: ElementRef;
  // listenFunc:Function;

  constructor(private _dataservice: DataService, private toastr: ToastrService,
    private token: TokenService, private router: Router,
    private categoryComp: CategoriesComponent,
    private changeDetect: ChangeDetectorRef,
    public cookieService: CookieService,
    private activatedRoute: ActivatedRoute,
    // private renderer:Renderer2,
    private http: HttpClient, private dom: DomSanitizer,
   


  ) {
    // this.gallary_icons();
    this.homeactive = true;
    this.getGalleryIconsSub$ = this._dataservice.getGalleryIcons.subscribe((list: any) => {
      console.log("lists", list)
      if (list.length !== 0) {
        this.vertical_menu(list);
      }
    })

    console.log("router", this.router.events)



    this.router.events.subscribe((res: any) => {
      // console.log("header-res",res)


      //  return false
      if (res.url != undefined) {
        var str = res.url;
        this.catagoryName = str.substring(0, 11);
        this.wishDetailName = str.substring(0, 6);
        console.log("res dff", this.wishDetailName)
        this.urlName = res.url
        if (res.url == '/') {
          this.companyActive = false;
          this.homeactive = true;
          this.ourProductActive = false
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
          this.wishListActive = false
        }
        else if (res.url == '/wishlist') {
          this.wishListActive = true;
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
        }
        else if (res.url == '/leadership' || res.url == "/history" || res.url == "/video_profile" || res.url == "/company_contact") {
          //contyact active
          this.companyActive = true;
          this.homeactive = false;
          this.ourProductActive = false
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
          this.wishListActive = false
        }
        else if (res.url == '/leather_ourproduct' || res.url == "/ourproduct_footwear" || res.url == "/ourproduct_handbags" || res.url == "/ourproduct_glowes") {
          // our product active
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = true;
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
          this.wishListActive = false
        }
        else if (res.url == '/social' || res.url == "/enviornmental" || res.url == "/sustainability" || res.url == "/csr" || res.url == "/our_people") {
          // responsibiluty active
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false;
          this.galaryActive = false
          this.resposibiltyActive = true
          this.contactActive = false
          this.awardActive = false;
          this.wishListActive = false
        }
        else if (res.url == '/awards') {
          // awards active
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false;
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = true;
          this.wishListActive = false
        }
        else if (res.url == '/corporate' || res.url == "/tannery" || res.url == "/contact_footwear" || res.url == "/contact_leather_goods" || res.url == "/contact_gloves") {
          // contact active
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false;
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = true
          this.awardActive = false;
          this.wishListActive = false
        }
        else if (this.catagoryName == '/categories') {
          this.galaryActive = true;
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false;
          this.resposibiltyActive = false
          this.contactActive = false;
          this.awardActive = false;
          this.wishListActive = false
        }

        else if (res.url == "/profile") {
          this.wishListActive = false;
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
        }
        else if (res.url == "/changepassword") {
          this.wishListActive = false;
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
        }
        else if (this.wishDetailName == '/wish/') {

          this.wishListActive = true;
          this.companyActive = false;
          this.homeactive = false;
          this.ourProductActive = false
          this.galaryActive = false
          this.resposibiltyActive = false
          this.contactActive = false
          this.awardActive = false;
        }
      }
    })
  }

  


  onhomeclick(name) {
    // alert("homecli")
    this.menuName = ""
    this.menuName = name;
    // debugger;

    if (this.menuName == 'Home') {
      this.homeactive = true;
      this.companyActive = false;

      this.ourProductActive = false
      this.galaryActive = false
      this.resposibiltyActive = false
      this.contactActive = false
      this.awardActive = false;

      this.hometoggle_4 = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle = false;

    }

    else if (this.menuName == 'Company') {
      this.hometoggle = true;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle_4 = false;

      if (this.urlName == "/leadership" || this.urlName == "/video_profile" || this.urlName == "/company_contact") {
        this.companyActive = true;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = false
        this.awardActive = false;

      }

    }
    else if (this.menuName == "ourproduct") {

      this.hometoggle_1 = true;
      this.hometoggle = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle_4 = false;


      if (this.urlName == "/leather_ourproduct" || this.username == "/ourproduct_footwear" || this.urlName == "/ourproduct_handbags" || this.urlName == "/ourproduct_glowes") {
        this.ourProductActive = true;
        this.companyActive = false;
        this.homeactive = false;

        this.galaryActive = false;
        this.resposibiltyActive = false;
        this.contactActive = false;
        this.awardActive = false;

      }

    }
    else if (this.menuName == "gallary") {

      this.hometoggle_2 = true
      this.hometoggle_1 = false;
      this.hometoggle = false;
      this.hometoggle_3 = false;
      this.hometoggle_4 = false;


      if (this.catagoryName == '/categories') {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = true
        this.resposibiltyActive = false
        this.contactActive = false;
        this.awardActive = false;

      }


    }

    else if (this.menuName == "Responsibilites") {

      this.hometoggle_3 = true;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle = false;
      this.hometoggle_4 = false;


      if (this.urlName == "/social" || this.urlName == "/enviornmental" || this.urlName == "/sustainability" || this.urlName == "/csr" || this.urlName == "/our_people") {
        this.resposibiltyActive = true
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false

        this.contactActive = false
        this.awardActive = false;

      }


    }
    else if (this.menuName == "Contact") {

      this.hometoggle_4 = true;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle = false;

      if (this.urlName == "/corporate" || this.urlName == "/tannery" || this.urlName == "/contact_footwear" || this.urlName == "/contact_leather_goods" || this.urlName == "/contact_gloves") {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = true
        this.awardActive = false;

      }

    }
    else if (this.menuName == 'Award') {

      this.hometoggle_4 = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle = false;

      if (this.urlName == '/awards') {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = false
        this.awardActive = true;
      }
    }
    else if (this.urlName == "/wishlist") {
      this.homeactive = false;
      this.ourProductActive = false
      this.galaryActive = false
      this.resposibiltyActive = false
      this.contactActive = false
      this.awardActive = false;
    }
  }

  // gallary_icons(){
  //   this.foot_icon =[]
  //   this.http.get("assets/icons/footwear.svg", {responseType: 'text' }).subscribe((res)=>{

  //     let foot_wear=this.dom.bypassSecurityTrustHtml(res);
  //     this.foot_icon.push({
  //       "key":"FOOTWEAR","value":foot_wear,"id":8
  //     })
  //   })
  //   this.http.get("assets/icons/bag.svg",{responseType: 'text' }).subscribe((res)=>{

  //     let bags=this.dom.bypassSecurityTrustHtml(res);
  //     this.foot_icon.push({
  //       "key":"BAGS","value":bags,"id":2
  //     })
  //   })

  //   this.http.get("assets/icons/gloves.svg",{responseType: 'text' }).subscribe((res)=>{

  //     let gloves=this.dom.bypassSecurityTrustHtml(res);
  //     this.foot_icon.push({
  //       "key":"GLOVES","value":gloves,"id":6
  //     })
  //   })

  //   this.http.get("assets/icons/concept.svg",{responseType: 'text' }).subscribe((res)=>{

  //     let concepts=this.dom.bypassSecurityTrustHtml(res);
  //     this.foot_icon.push({
  //       "key":"CONCEPT","value":concepts,"id":7
  //     })
  //   })

  //   this.http.get("assets/icons/belts.svg",{responseType: 'text' }).subscribe((res)=>{

  //     let belts=this.dom.bypassSecurityTrustHtml(res);
  //     this.foot_icon.push({
  //       "key":"BELTS","value":belts,"id":4
  //     })
  //   })

  //   this.http.get("assets/icons/wallets.svg",{responseType: 'text' }).subscribe((res)=>{

  //     let wallets=this.dom.bypassSecurityTrustHtml(res);
  //     this.foot_icon.push({
  //       "key":"WALLETS","value":wallets,"id":3
  //     })
  //   })

  //   console.log(this.foot_icon);
  //   this.vertical_menu(this.foot_icon)
  // }

  // header_fonts() {
  //   this._dataservice.getFonts().subscribe((res) => {
  //     console.log("fontsss", res)
  //   })
  // }

  // header_cmsfonts() {
  //   this._dataservice.getFontscms().subscribe((res) => {
  //     console.log("fontssscms", res)
  //   })
  // }

  ngOnInit(): void {

    // this.listenFunc = this.renderer.listen('document','mousemove',evntListen => {
    //   console.log("event listening",evntListen);
    // });

    // this.header_fonts();
    // this.header_cmsfonts();
    console.log("proPicsrc",this.proPic);
    setTimeout(() => {
      this.isReadOnly = false;
    }, 0);

    $(document).ready(function () {
      $('.nav-link').click(function () {
        $('.nav-link').removeClass('active');
        $(this).addClass("active");
      });
    }
    );

    $(document).ready(function () {

      if ($(window).width() < 767) {
        $('.mob_navs').click(function () {
          $(this).next('#subb_menu').toggle()
        })
      }

      if ($(window).width() < 767) {
        $('.dropdown__selected').click(function () {
          $(this).next('.dropdown__list').toggle()
        })
        $('.header-sec').click(function () {
          $('body').toggleClass('menutoggled')
        })
      }

    });



    $(document).ready(function () {
      $("#navbarSupportedContent .rmv").click(function () {
        $("#navbarSupportedContent").removeClass("show");
      });
    });

    this.isloggedIn = localStorage.getItem('token') ? true : false;
    this.username = localStorage.getItem('name');

    if (this.cookieService.get('remember') != undefined) {
      if (this.cookieService.get('remember') == 'Yes') {
        this.loginData.user_name = this.cookieService.get('username');
        this.loginData.password = this.cookieService.get('password');
        this.remember = true;
      }
    }
  }

  setSubItem(name: string) {
    // this.receiveItem=name;
    this._dataservice.changeMessage(name);
    localStorage.removeItem('page');
    localStorage.removeItem('showmore');
    // this.renderer.setElementClass(event.target,"selected",true);
  }

  getRightArr: Array<any> = [];

  vertical_menu(iconsArr) {
    var self = this;
    self._dataservice.get_vertical_menu().subscribe((res: any) => {
      console.log("menussss", res)

      self.menus = res.data ? res.data : [];

      let menu_list_arr = self.menus;



      if (menu_list_arr.length !== 0 && iconsArr.length !== 0) {
        this.getRightArr = [];
        menu_list_arr.forEach((list) => {
          iconsArr.forEach((ele) => {
            if (list?.id === ele?.id) {
              let obj = {
                id: list.id,
                name: list.name,
                status: list.status,
                thumbnailImage: list.thumbnail_image,
                rightIcon: ele.value
              }
              this.getRightArr.push(obj);
            }
          });
        });

        self.menus = this.getRightArr;
        console.log("right array", self.menus)
      }


      // if(self.menus.length !== 0){
      //   for(let i=0;i<=self.menus.length;i++){
      //     for(let j=0;j<this.foot_icon.length;j++){
      //       if(self.menus[i]['id'] == this.foot_icon[j]['id']){
      //         self.menus[i]["id"] =  self.menus[i]["id"];
      //         self.menus[i]["name"] =self.menus[i]["name"];
      //         self.menus[i]["status"] =self.menus[i]["status"];
      //         self.menus[i]["thumbnail_image"] =self.menus[i]["thumbnail_image"];
      //         self.menus[i]["image"] =this.foot_icon[j]["value"];

      //       }
      //     }
      //   }

      //   self.menus = self.menus.map((list) =>{
      //     this.foot_icon.map((el)=>{
      //       if(list.id==el.id){
      //         return {
      //           ...list,
      //           image : el.value
      //         }
      //       }
      //     })
      //   })
      //   console.log(self.menus)
      //   console.log(this.foot_icon)
      // }

      setTimeout(() => {
        this.subscription = this._dataservice.currentMessage.subscribe(message => {
          this.receiveItem = message

          if (message == "default message") {

            const lastUrlSegment = this.router.url.split('?')[0].split('/').pop();
            console.log("lastUrlSegment ff", lastUrlSegment)
            this.receiveItem = lastUrlSegment;

          }
        })
      }, 0);
    })
  }
  onSubmit() {
    var self = this;
    self._dataservice.login(self.loginData).subscribe((res: any) => {
      if (res.status == "Success") {
        self.isloggedIn = true;
        self.username = res.data.name;
        this.token._set_data(res);

        if (this.remember) {
          this.cookieService.put('remember', "Yes");
          this.cookieService.put('username', this.loginData.user_name);
          this.cookieService.put('password', this.loginData.password);
        } else {
          this.cookieService.put('remember', "No");
          this.cookieService.put('username', "");
          this.cookieService.put('password', "");
        }

        // localStorage.setItem('token', res.access_token);
        // localStorage.setItem('token_type', res.token_type);
        // localStorage.setItem('expires_at', res.expires_at);
        // localStorage.setItem('name', res.data.name);
        self.toastr.success(res.message, 'Success');
        $('#myModal').modal('hide');
        let id = localStorage.getItem('vertical_id');
        let paramName = localStorage.getItem('catagoryName');
        this.router.navigate(['/']);
        if (id && paramName) {
          this.categoryComp.revoke()
        }

      } else {
        self.toastr.warning(res.message, 'Warning');
      }
    }, err => {
      console.log("err ", err);
      // self.toastr.warning(res.message, 'Warning');
    })
  }

  logout() {
    var self = this;

    self._dataservice.logOut().subscribe((res: any) => {
      if (res.status == "Success") {
        self.token._logout()
        self.isloggedIn = false;
        self.toastr.success(res.message, 'Success');
        self.router.navigateByUrl('/')
      } else {
        self.toastr.warning('Something went wrong', 'Warning');
      }

    }, err => {
      self.toastr.warning('Something went wrong', 'Warning');
    })
  }

  gotoRegistration() {
    var window: any;
    $('#myModal').modal('hide');
    // this.router.navigateByUrl('/registration')
    this.router.navigateByUrl('/newregistration')
  }

  get getInitialName() {
    // console.log("initial",this.username.split(' ').map(n => n[0]).join(''));
    
    // if (this.userName) {

      return this.username.split(' ').map(n => n[0]).join('');
    // }

  }

  get userName() {
    return localStorage.getItem('name');
  }

  get proPic() {
    return localStorage.getItem('profile_image');
  }

  onClickProfile() {
    // this.router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe(res => {
    //   if (this.router.url == "/profile") {
    //       console.log("hello",res)
    //   }
    // });
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  forgotPassword() {
    $('#myModal').modal('hide');
    $('#forgotModal').modal('show');
  }
  gotoLogin(f) {
    f.resetForm()
    $('#myModal').modal('show');
    $('#forgotModal').modal('hide');
  }
  forgotPass() {
    this.submitted = true;
    // this.fpass.email=this.loginData.user_name?this.loginData.user_name:"";
    var reqData = {
      email: this.fpass.email
    }
    this._dataservice.forgotPassword(reqData).subscribe((res: any) => {
      if (res?.status == "Success") {
        this.toastr.success(res?.message);

        $('#forgotModal').modal('hide');
        $('#myModal').modal('show');
      } else if (res?.status == "Failed") {
        this.toastr.error(res?.message)
      }
    },
      err => {

      })
  }

  goTo(route, item: any) {
    this.router.navigate([route], {
      queryParams:
      {
        id: (item.id ? item.id : ''),
        name: (item.name ? item.name : '')
      },
      queryParamsHandling: 'merge'
    }
    );

    // [routerLink]="['/categories', item.id,item.name]"
  }
  onClickedOutside(e: Event) {

    if (this.menuName == 'Home') {
      this.homeactive = true;
      this.companyActive = false;

      this.ourProductActive = false
      this.galaryActive = false
      this.resposibiltyActive = false
      this.contactActive = false
      this.awardActive = false;

    }

    else if (this.menuName == 'Company') {
      this.hometoggle = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle_4 = false;

      if (this.urlName == "/leadership" || this.urlName == "/video_profile" || this.urlName == "/company_contact") {
        this.companyActive = true;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = false
        this.awardActive = false;
      }

    }
    else if (this.menuName == "ourproduct") {

      this.hometoggle_1 = false;
      this.hometoggle = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle_4 = false;


      if (this.urlName == "/leather_ourproduct" || this.username == "/ourproduct_footwear" || this.urlName == "/ourproduct_handbags" || this.urlName == "/ourproduct_glowes") {
        this.ourProductActive = true;
        this.companyActive = false;
        this.homeactive = false;

        this.galaryActive = false;
        this.resposibiltyActive = false;
        this.contactActive = false;
        this.awardActive = false;
      }
    }
    else if (this.menuName == "gallary") {

      this.hometoggle_2 = false
      this.hometoggle_1 = false;
      this.hometoggle = false;
      this.hometoggle_3 = false;
      this.hometoggle_4 = false;


      if (this.catagoryName == '/categories') {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = true
        this.resposibiltyActive = false
        this.contactActive = false;
        this.awardActive = false;
      }


    }

    else if (this.menuName == "Responsibilites") {

      this.hometoggle_3 = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle = false;
      this.hometoggle_4 = false;


      if (this.urlName == "/social" || this.urlName == "/enviornmental" || this.urlName == "/sustainability" || this.urlName == "/csr" || this.urlName == "/our_people") {
        this.resposibiltyActive = true
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false

        this.contactActive = false
        this.awardActive = false;
      }


    }
    else if (this.menuName == "Contact") {

      this.hometoggle_4 = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle = false;

      if (this.urlName == "/corporate" || this.urlName == "/tannery" || this.urlName == "/contact_footwear" || this.urlName == "/contact_leather_goods" || this.urlName == "/contact_gloves") {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = true
        this.awardActive = false;
      }
    }
    else if (this.menuName == 'Award') {

      this.hometoggle_4 = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle = false;
      if (this.urlName == '/awards') {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = false
        this.awardActive = true;
      }
    }


  }

  mouseLeave(text: any) {
    this.hometoggle_4 = false;
    this.hometoggle_1 = false;
    this.hometoggle_2 = false;
    this.hometoggle_3 = false;
    this.hometoggle = false;
  }


  ngOnDestroy() {
    // this.listenFunc();
    this.getGalleryIconsSub$.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

  }

  onClickingOutSide(e: any) {
    this.companyTog = false;
    this.out_producrt_toggle = false;
    this.gallary_toggle = false;
    this.respose_toggle = false;
    this.contact_toggle = false;
  }


  myFun(name) {
    console.log(name)
    this.toggleMenu(name);
  }

  // func(value) {
  //   console.log(value)
  //   setTimeout(() => {
  //     this.companyTog = false;
  //     this.out_producrt_toggle = false;
  //     this.gallary_toggle = false;
  //     this.respose_toggle = false;
  //     this.contact_toggle = false;
  //   }, 3000);
  // }

  leaveToggleMenu() {
    this.companyActive = false;
    this.homeactive = false;
    this.ourProductActive = false;
    this.galaryActive = false;
    this.resposibiltyActive = false;
    this.contactActive = false;
    this.awardActive = false;
  }

  ngDoCheck(): void {
      
  }

  toggleMenu(name) {

    // alert(1)
    console.log("name of toggle", name);
    if (name == 'Home') {
      this.homeactive = true;
      // this.companyActive = false;

      // this.ourProductActive = false
      // this.galaryActive = false
      // this.resposibiltyActive = false
      // this.contactActive = false
      // this.awardActive = false;



      // this.companyTog = false;
      // this.out_producrt_toggle = false;
      // this.gallary_toggle = false;
      // this.respose_toggle = false;
      // this.contact_toggle = false;

    }

    else if (name == 'Company') {

      this.companyTog = !this.companyTog;
      console.log("values", this.companyTog)
      this.out_producrt_toggle = false;
      this.gallary_toggle = false;
      this.respose_toggle = false;
      this.contact_toggle = false;
      // this.func(this.companyTog)
      if (this.urlName == "/leadership" || this.urlName == "/video_profile" || this.urlName == "/company_contact") {
        this.companyActive = true;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = false
        this.awardActive = false;
      }

    }
    else if (name == "ourproduct") {

      this.companyTog = false;
      this.out_producrt_toggle = !this.out_producrt_toggle;
      this.gallary_toggle = false;
      this.respose_toggle = false;
      this.contact_toggle = false;

      // this.func(this.out_producrt_toggle)
      if (this.urlName == "/leather_ourproduct" || this.username == "/ourproduct_footwear" || this.urlName == "/ourproduct_handbags" || this.urlName == "/ourproduct_glowes") {
        this.ourProductActive = true;
        this.companyActive = false;
        this.homeactive = false;

        this.galaryActive = false;
        this.resposibiltyActive = false;
        this.contactActive = false;
        this.awardActive = false;

      }

    }
    else if (name == "gallary") {

      this.companyTog = false;
      this.out_producrt_toggle = false;
      this.gallary_toggle = !this.gallary_toggle;
      this.respose_toggle = false;
      this.contact_toggle = false;

      // this.func(this.gallary_toggle);

      if (this.catagoryName == '/categories') {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = true
        this.resposibiltyActive = false
        this.contactActive = false;
        this.awardActive = false;

      }


    }

    else if (name == "Responsibilites") {

      this.companyTog = false;
      this.out_producrt_toggle = false;
      this.gallary_toggle = false;
      this.respose_toggle = !this.respose_toggle;
      this.contact_toggle = false;

      // this.func(this.respose_toggle);

      if (this.urlName == "/social" || this.urlName == "/enviornmental" || this.urlName == "/sustainability" || this.urlName == "/csr" || this.urlName == "/our_people") {
        this.resposibiltyActive = true
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false

        this.contactActive = false
        this.awardActive = false;

      }


    }
    else if (name == "Contact") {

      this.companyTog = false;
      this.out_producrt_toggle = false;
      this.gallary_toggle = false;
      this.respose_toggle = false;
      this.contact_toggle = !this.contact_toggle;

      // this.func(this.contact_toggle);

      if (this.urlName == "/corporate" || this.urlName == "/tannery" || this.urlName == "/contact_footwear" || this.urlName == "/contact_leather_goods" || this.urlName == "/contact_gloves") {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = true
        this.awardActive = false;

      }

    }
    else if (name == 'Award') {

      this.hometoggle_4 = false;
      this.hometoggle_1 = false;
      this.hometoggle_2 = false;
      this.hometoggle_3 = false;
      this.hometoggle = false;

      if (this.urlName == '/awards') {
        this.companyActive = false;
        this.homeactive = false;
        this.ourProductActive = false
        this.galaryActive = false
        this.resposibiltyActive = false
        this.contactActive = false
        this.awardActive = true;

      }
    }
    else if (this.urlName == "/wishlist") {
      this.homeactive = false;
      this.ourProductActive = false
      this.galaryActive = false
      this.resposibiltyActive = false
      this.contactActive = false
      this.awardActive = false;

    } else {
      
    }
    console.log("hometoggle_4", this.hometoggle_4)
    console.log("hometoggle_3", this.hometoggle_3)
    console.log("hometoggle_2", this.hometoggle_2)
    console.log("hometoggle_1", this.hometoggle_1)
    console.log("hometoggle", this.hometoggle)

  }
  onMouseEnterInList(){
  //   console.log("mpuse enter");
  //   let title = this.el.nativeElement.querySelector(".profile-dropdown")
  //   let data = this.el.nativeElement.querySelector(".profile-dropdown-btn")
  //   let profile = this.el.nativeElement.querySelector(".profile-dropdown-menu")
  //   title?.classList.remove("show");
  //   profile?.classList.remove("show");
  //   data?.setAttribute( "aria-expanded", "false" );
  }

  handleRoutingChange(data,url?:any){
    // let title = this.el.nativeElement.querySelectorAll(".dropdown-menu");
    // console.log("display propety",title)

    // title?.classList.add("notshownmenu");
    localStorage.removeItem('selected-sub-category');
    localStorage.removeItem('selected-attribute-array')
    console.log("router enter")
    if(url){
      this.router.navigate([data,url.id,url.name]);
    }
    else{
      this.router.navigate([data]);
    }

  }


}

import { Component, OnInit } from '@angular/core';
import { DataService } from "../services/data.service";

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  menus: any = [];
  homeData: any;
  constructor(private _dataservice: DataService) {
    localStorage.removeItem('selected-sub-category');
    localStorage.removeItem('selected-attribute-array');
   }

  ngOnInit() {
    this.vertical_menu();
    this.getHomeData();
// this. getfont();


    $(document).bind('click', function (e) {
      if (!($(e.target).parent().hasClass("header-sec")) && !($(e.target).parent().parent().hasClass("header-sec")) && !($(e.target).parent().parent().parent().hasClass("header-sec")) && !($(e.target).parent().parent().parent().parent().parent().hasClass("header-sec")) && !($(e.target).parent().parent().parent().parent().parent().parent().hasClass("header-sec"))) {
        if (($('.navbar-collapse').hasClass('show'))) {
          $('.navbar-toggler').trigger('click');
        }
      }
    });


  }
  removeItem(){
    localStorage.removeItem('page')
    localStorage.removeItem('showmore')
  }
  


  vertical_menu() {
    var self = this;
    self._dataservice.get_vertical_menu().subscribe((res: any) => {
      console.log("menussss",res)
      self.menus = res.data ? res.data : [];
    })
  }
  getHomeData() {
    var self = this;

    self._dataservice.get_homedata().subscribe((res: any) => {
      console.log("menussss",res)
      self.homeData = res.data ? res.data : [];
      console.log("home data" , this.homeData)
    })
  }
 

 
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  sharedData:string;

  private gallaryIconService = new BehaviorSubject<any>([]);
  getGalleryIcons = this.gallaryIconService.asObservable();

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  private apiUrl: string;

  private subAttrService = new BehaviorSubject<any>([]);
  getSelectedSubAndAttrService = this.subAttrService.asObservable();

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }
  changeMessage(message: string) {
    // alert(message)
    this.messageSource.next(message)
  }

  getGallaeryIcon(val:any){
      this.gallaryIconService.next(val)
  }

  getGalleryDataBackService(data: any) {
    this.subAttrService.next(data);
  }

  login(data: any) {
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    return this.http.post(this.apiUrl +'/auth/login', form_data);
  }

  logOut() {
    return this.http.get(this.apiUrl +'/auth/logout');
  }

  get_industries() {
    return this.http.get(this.apiUrl +'/industry/list');
  }
  get_clienttype() {
    return this.http.get(this.apiUrl +'/client/list');
  }
  getIcons() {
    return this.http.get(this.apiUrl +'/wishlisticon/list');
  }

  get_profile() {
    return this.http.get(this.apiUrl +'/auth/profile');
  }

  registerUser(data: any) {
    return this.http.post(this.apiUrl +'/registration', data)
  }

  edit_profile(data: any) {
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    return this.http.post(this.apiUrl + '/auth/edit', form_data);
  }

  update_password(data: any) {
    return this.http.post(this.apiUrl + '/auth/changepassword', data);
  }

  get_homedata() {
    return this.http.get(this.apiUrl +'/cms/home_list');
  }
  get_vertical_menu() {
    return this.http.post(this.apiUrl + '/user/category', {});
  }

  get_productsById(id) {
    return this.http.post(this.apiUrl+ '/user/products', {vertical_id: id});
  }





  //category product wishlist details
  get_productDetailsByCms(id)
  {
    return this.http.get(this.apiUrl+ '/producdetail/cms/'+id);
  }

  updateWishList(data):Observable<any>
  {
    return this.http.post(this.apiUrl+ '/wishlist/add',data);
  }


  //category filter
  categoryList(id):Observable<any>
  {
    return this.http.post(this.apiUrl+ '/category/list',{vertical_id:id});
  }


  categoryFilter(data:any):Observable<any>
  {

    return this.http.post(this.apiUrl+ '/category/filter',data);
  }

  //wishlist page
  onDisplayWishlist(id):Observable<any>
  {
    return this.http.get(this.apiUrl+ '/wishlist/list/'+id)
  }

  //wishlist detail
  onDisplayWishlistDetails(datas):Observable<any>
  {
    return this.http.post(this.apiUrl+ '/wishlist/detail',datas)
  }

  onSendToKH(datas):Observable<any>
  {
  return this.http.post(this.apiUrl+ '/sentKH',datas)
  }




  category(data: any) {
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    return this.http.post(this.apiUrl + '/category/create', form_data);
  }

  categorylist(id) {
    return this.http.post(this.apiUrl + '/category/wish_cat_list',{"product_id":id});

  }

  getCatagoryList(id)
  {
    return this.http.post(this.apiUrl + '/category/list', {vertical_id: id});
  }
  // getSubCatagoryList(id:any)
  // {
  //   return this.http.post(this.apiUrl + '/user/sub-category',{vertical_id:id});
  // }

  getGenderCatagoryList(data)
  {
    return this.http.post(this.apiUrl + '/product/gender',data);
  }



  get_productsDetailsById(id) {
    return this.http.get(this.apiUrl+ '/product/details/'+id);
  }

  //wishlist page remove
  removeWishlist(data):Observable<any>
  {
    return this.http.post(this.apiUrl+ '/wishlist/remove',data)
  }

  getListNon(data){
    return this.http.post(this.apiUrl+ '/nonuser/products',data)
  }

  getSizeList(data){
    return this.http.post(this.apiUrl+ '/product/size-dropdown',data)
  }
  getMaterialList(data){
    return this.http.post(this.apiUrl+ '/product/material-dropdown',data)
  }

  filterById(data:any){
  return this.http.post(this.apiUrl+"/product/size",data)
  }
  getRelatedProducts(data:any){
    return this.http.post(this.apiUrl+"/product/related",data)
  }
  forgotPassword(data:any){
    return this.http.post(this.apiUrl+"/auth/forgotpassword",data)
  }



  catagorydropdown(id: any)
  {
    console.log('Came',id)
    return this.http.post(this.apiUrl+"/user/category/attribute",id)
  }

  getFilteredDropdownData(datas: any)
  {
    return this.http.post(this.apiUrl+"/user/category/attribute/filter",datas)
  }
contactUs(data:any){
  return this.http.post(this.apiUrl+"/contactus",data)
}
_deleteCategory(id){
  return this.http.post(this.apiUrl+"/wishlist/catremove", {"wish_cat_id":id})
}

onEdit(data){
  return this.http.post(this.apiUrl +"/wishlist/catedit",data);
}
getFontsProduct(){
  return this.http.get(this.apiUrl+"/setting/fonts?type=product")
}
  // edit and delete wishlist 
  categoryEditName(data:any){
    return this.http.post(this.apiUrl+ '/wishlist/catedit',data);
  }
  categoryDelete(data:any){
    return this.http.post(this.apiUrl+ '/wishlist/catdelete',data);
  }
}


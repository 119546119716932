import { Component, ViewChild } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';


declare var $: any
import { NgwWowService } from 'ngx-wow';
import { forkJoin, Subscription } from 'rxjs';

import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from './services/data.service';

const MINUTES_UNITL_AUTO_LOGOUT = 60// in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY = 'lastAction';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public foot_icon: Array<any> =[];
  response = [];

gallaeryDetailArr=[
  {"key":"FOOTWEAR","id":8},
 { "key":"BAGS","id":2},
 { "key":"GLOVES","id":6},
 { "key":"CONCEPT","id":7},
 { "key":"BELTS","id":4},
 { "key":"WALLETS","id":3}
]

public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }
  title = 'kH Group';
  layoutShow: boolean;
  private wowSubscription: Subscription;
  data: any;

  constructor(private swUpdate: SwUpdate, private wowService: NgwWowService, 
    private router: Router,private http:HttpClient,private dom:DomSanitizer,private dataService:DataService) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
    this.swUpdate.available.subscribe(event => {
      console.log('New update available');
      this.updateToLatest();
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      console.log("subscribe ");

      this.wowService.init();
    });

    const request1 = this.http.get("assets/icons/footwear.svg", {responseType: 'text' });
    const request2 = this.http.get("assets/icons/bag.svg",{responseType: 'text' });
    const request3 = this.http.get("assets/icons/gloves.svg",{responseType: 'text' });
    const request4 = this.http.get("assets/icons/concept.svg",{responseType: 'text' });
    const request5 = this.http.get("assets/icons/belts.svg",{responseType: 'text' });
    const request6 = this.http.get("assets/icons/wallets.svg",{responseType: 'text' });
    const requestArray = [];
    requestArray.push(request1);
    requestArray.push(request2);
    requestArray.push(request3);
    requestArray.push(request4);
    requestArray.push(request5);
    requestArray.push(request6);
    
    forkJoin(requestArray).subscribe(results => {
      console.log("results",results);
      this.response = results;
      console.log("afterres",this.response.length)
      if(this.response.length !==0){
        this.gallary_icons(this.response)
      }
    });

  
  }

  

  ngOnInit() {
    $("body").on("contextmenu", "img", function (e) {
      return false;
    });
    // this.gallary_icons();
  }
  gallary_icons(resArr){
    this.foot_icon =[]
    for(let i=0;i<resArr.length;i++){
      let foot_wear=this.dom.bypassSecurityTrustHtml(resArr[i]);
      this.foot_icon.push({
        "key":this.gallaeryDetailArr[i].key,"value":foot_wear,"id":this.gallaeryDetailArr[i].id
      })
    }

    
    // this.http.get("assets/icons/footwear.svg", {responseType: 'text' }).subscribe((res)=>{
      
    //   let foot_wear=this.dom.bypassSecurityTrustHtml(res);
    //   this.foot_icon.push({
    //     "key":"FOOTWEAR","value":foot_wear,"id":8
    //   })
    // })
    // this.http.get("assets/icons/bag.svg",{responseType: 'text' }).subscribe((res)=>{
      
    //   let bags=this.dom.bypassSecurityTrustHtml(res);
    //   this.foot_icon.push({
    //     "key":"BAGS","value":bags,"id":2
    //   })
    // })

    // this.http.get("assets/icons/gloves.svg",{responseType: 'text' }).subscribe((res)=>{
      
    //   let gloves=this.dom.bypassSecurityTrustHtml(res);
    //   this.foot_icon.push({
    //     "key":"GLOVES","value":gloves,"id":6
    //   })
    // })

    // this.http.get("assets/icons/concept.svg",{responseType: 'text' }).subscribe((res)=>{
      
    //   let concepts=this.dom.bypassSecurityTrustHtml(res);
    //   this.foot_icon.push({
    //     "key":"CONCEPT","value":concepts,"id":7
    //   })
    // })

    // this.http.get("assets/icons/belts.svg",{responseType: 'text' }).subscribe((res)=>{
      
    //   let belts=this.dom.bypassSecurityTrustHtml(res);
    //   this.foot_icon.push({
    //     "key":"BELTS","value":belts,"id":4
    //   })
    // })

    // this.http.get("assets/icons/wallets.svg",{responseType: 'text' }).subscribe((res)=>{
      
    //   let wallets=this.dom.bypassSecurityTrustHtml(res);
    //   this.foot_icon.push({
    //     "key":"WALLETS","value":wallets,"id":3
    //   })
    // })

    console.log("foot",this.foot_icon);
    this.dataService.getGallaeryIcon(this.foot_icon)
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {

      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout) {

      localStorage.clear();
      if (!localStorage.getItem('foo')) {
        localStorage.setItem('foo', 'no reload')
        location.reload()
      } else {
        localStorage.removeItem('foo')
      }

    }
  }



  onActivate(event) {

    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
    // localStorage.removeItem('selected-sub-category');
    // localStorage.removeItem('selected-attribute-array')
  }

  updateToLatest(): void {
    console.log('Updating to latest version.');
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }

}

import { Component, Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// @Injectable({
//   providedIn: 'root'
// })

/// for all module it will required this purpose of making very widely use in case

@Component({
  selector: 'app-confirmation-dialog',
  template: `
<div class="modal-header delete_head" id="confirm_modal">
    <h4 class="modal-title">Warning !</h4>
  
    </div>
    <div class="modal-body">
   Do you want to discard the changes? 
    </div>
    <div class="modal-footer c_footer">
      <button type="button" title="Stay this page" class="btn btn-danger" (click)="onClickCancel()"> No</button>
      <button type="button" title="Leave the page without saving " class="btn btn-primary" (click)="onClickConfirm()">Yes</button>
    
    </div>


  `,
 styles:[
    ' .modal-footer { border-top: 0px !important;    display: flex;      flex-wrap: wrap;      align-items: center;      justify-content: center;      padding: .75rem;      border-top: 1px solid #dee2e6;      border-bottom-right-radius: calc(.3rem - 1px);      border-bottom-left-radius: calc(.3rem - 1px);}'
 ]
})
export class ConfirmationComponent  {
  data: any;

comp:any ="app-manager-list-edit"

  constructor(
  private  dialogRef: MatDialogRef<ConfirmationComponent, boolean>,
  ) {
    
  }

  onClickCancel() {

    this.dialogRef.close(false);
  }



  onClickConfirm() {
    this.dialogRef.close(true);
  }
  save(){

  } 

}

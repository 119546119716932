import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyTermsViewComponent } from './privacy-terms-view/privacy-terms-view.component';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  homeData: any;
  wishlistRout:boolean = false;
  constructor(private _dataservice:DataService,private sanitizer:DomSanitizer,private matDialog:MatDialog,private router : Router) {
    // console.log("route",router.url,router.url.split("/"));
    // if(router.url == '/wishlist')
    // {
    //   console.log("route",router.url);
    //   this.wishlistRout = true;
    // }
   }

  ngOnInit(): void {

    this.getHomeData();

  //   $('#myModal').modal({
  //     backdrop: 'static',
  //     keyboard: false  // to prevent closing with Esc button (if you want this too)
  // })

    // var modal = document.getElementById('homeModal'); 
    //  window.onclick = function(event) {
    //     if (event.target == modal) {
    //       modal.style.display = "none";
    //     }
    // }
// $(document).ready(function () {
//     $(function () {
//       $("#button2").click(function () {
//           $("#homeModal").modal({ backdrop: "static ", keyboard: false });
//       });
//   });
// });

  }

  public homeDataValue:any = {};

  getHomeData() {
    // var self = this;
    this._dataservice.get_homedata().subscribe((res: any) => {
      console.log("home data response",res)
      this.homeData = res.data ? res.data : [];
      // this.homeDataValue = self.homeData;
      // console.log("home data value" , this.homeDataValue)
    })
  }
  setValueObj:any = {};
  handlePrivacyTermsConditionPopup(showValue:any,eventValue:string,showType:boolean) {
    console.log("event value",eventValue);
    console.log("show or not",showType);
    console.log("show value",showValue);
    // this.homeDataValue = this.sanitizer.bypassSecurityTrustHtml(showValue);
    this.setValueObj = {};
    this.setValueObj = {
      'event':eventValue,
      'type':showType
    }

    this.matDialog.open(PrivacyTermsViewComponent, {
      data: {
        value: {
          setValue: this.setValueObj,
          homeData:showValue
        },
      },
      width:'50%'
    }).afterClosed().subscribe((response)=> {
      console.log("popup response",response);
    })
  }
}

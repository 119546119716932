import { JsonPipe, KeyValue, Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { MasonryOptions } from 'ng-masonry-grid';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { filter, pairwise, retry } from 'rxjs/operators';
import { TokenService } from 'src/app/services/token.service';
import { DataService } from "../../services/data.service";
import { HostListener } from '@angular/core';
import { log } from 'console';
declare var $: any

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],

})
export class CategoriesComponent implements OnInit {
  message: string;
  subscription: Subscription;
  // @ViewChild(HeaderComponent)
  // public myHeadComp!:HeaderComponent
  // store: RecordStore = {};
  resultObj: any = {};
  vertical_id: any;
  productList: any = [];
  productListPart: any = [];
  vertical_menus: any = [];
  productType: number;
  loaded: boolean = false;
  loadedAll: boolean = false;
  catagoryListArray: any = [];
  productListShownArray = []
  storeSelectedValues: any;
  selectedValue: any;
  fonts_array_data: any = {};
  checkboxArray: any = [];
  closeAfterFilter: boolean = true
  storeId: any;
  user_id: any;
  checkedstore: any;
  pro_name: any;
  genderListNoProduct: boolean = false
  selectedGender: any;
  genderList: any = [];
  catagoryName: any = [];
  catagoryListVerticalIds: any = []
  myownclasses: boolean;
  catagoryNameChange: any;
  showns: boolean = false;
  wish_catagory_id;
  tab_menus: any;
  menu_id: any = null;
  menu_name: any;
  checkedValue: any = "1" || true;
  subCatagoryListArray: any = [];
  val: any[];
  sizeList: any = [];
  materialList: any = [];
  sizeVal: any;
  materialVal: any;
  hideGender: boolean = false;
  loading: boolean = false;
  catagoryDropDownMain: any = []
  valueObj: any = {}
  arrayFilters = [];
  labelIds = [];
  labelOptions = [];
  selectedItem: any;
  selectedSubItem: any;
  selectedAttrItem: any;
  idObj: any = {};
  subcategoryId: any;
  optionsName: any;
  selectedSubItemName: any;
  isShownCat: boolean = false;
  isShowSubCat: boolean = false;
  isShowAttribute: boolean = false;
  selectedProd: any;
  labelArray: any;
  currentPage: any = 1;
  p: any = 1;
  perPage: number = 8;
  currentValue: number = 0;
  // unitTrustsPnl: number;
  values: any;
  backSelectedCategory: any;
  backSelectedSubCategory: any;
  backSelectedAttributes: any;
  typeOfRoute: string = 'current';
  isShowMore: boolean = false;
  isNotShownMore: boolean = true;
  showMasonry: boolean = false;
  leathers: any;
  isAdded: boolean;
  savePart: any;
  categoryName: any = "";
  uploadImg: any = null;
  userid: any;
  product_img_list: any = [];
  productDetails: any;
  product_name: any;
  sub_cat_id: any;
  mydatas: any = []
  mainImg: any;
  vertical_Name: any;
  ver_id: any;
  cate_id: any;
  imgSrc: any;
  relatedProductList: any = [];
  popupimage: any;
  iscategorynameedit: boolean = false;
  isloggedIn: boolean = false;
  deleteleatset: number;
  showMoreFromLocalStorage: string;
  usermail: any;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
    localStorage.removeItem('selected-sub-category');
    localStorage.removeItem('selected-attribute-array');
  }
  gallaeryDetailArr = [
    { "key": "FOOTWEAR", "id": "8" },
    { "key": "BAGS", "id": "2" },
    { "key": "GLOVES", "id": "6" },
    { "key": "CONCEPT", "id": "7" },
    { "key": "BELTS", "id": "4" },
    { "key": "WALLETS", "id": "3" }
  ]
  // getBackSubAttr$:Subscription;
  @ViewChild(NgxMasonryComponent, { static: false })
  public masonry: NgxMasonryComponent;

  public myOptions: MasonryOptions = {
    gutter: 20,
  };

  constructor(private _dataservice: DataService,
    private tokenService: TokenService,
    private router: Router,
    private route: ActivatedRoute, private toastr: ToastrService, private _location: Location,
    private changeDetect: ChangeDetectorRef,
  ) {

    this.checkedValue = 1;

    let selectedSubCategory = JSON.parse(localStorage.getItem('selected-sub-category'));

    let selectedAttributesList = JSON.parse(localStorage.getItem('selected-attribute-array'));
    this.showMoreFromLocalStorage = localStorage.getItem('showmore');
    this.usermail = localStorage.getItem('email');
    console.log("email", this.usermail);

    //  if(this.showMoreFromLocalStorage !== null && this.showMoreFromLocalStorage === 'true' ){
    //   console.log("showMoreFromLocalStorage",this.showMoreFromLocalStorage,typeof(this.showMoreFromLocalStorage));
    //   this.isNotShownMore = true;
    //  }



    //  console.log("selected category constructeer",selectedSubCategory);

    //  console.log("selecetd attribure constructor",selectedAttributesList);

    this.backSelectedSubCategory = selectedSubCategory;
    this.backSelectedAttributes = selectedAttributesList;
    if (this.backSelectedSubCategory !== null || this.backSelectedAttributes !== null) {
      this.typeOfRoute = 'another';
      console.log("another")
    }


    if (this.backSelectedAttributes !== null && this.backSelectedAttributes !== undefined) {
      this.filterAttributeArray = this.backSelectedAttributes;
    }
  }


  changePage(page) {
    console.log("page inner", page);
    this.p = page;
    this.handleToCheckShowMore(this.p, this.productListPart.length);
    localStorage.setItem("page", JSON.stringify(page))
  }

  handleToCheckShowMore(page, productListLength) {
    this.currentValue = 0;
    let totalLength = productListLength;
    console.log("total count", totalLength);
    this.currentValue = totalLength - (page * 8);
    console.log("currentval", this.currentValue)
    console.log("page", page)
    if (this.currentValue <= 0) {
      this.isNotShownMore = false;
    }
    else {
      this.isNotShownMore = true;

    }
  }


  handleToGetFilterValuesBack(selectedSubCategory, selectedAttributesList, type) {

    console.log("common back functionality attribute list", selectedAttributesList);

    console.log("common back functionality sub category", selectedSubCategory);

    if (selectedAttributesList !== null && selectedAttributesList !== undefined) {

      if (selectedAttributesList.length !== 0) {
        if (type !== 'another') {
          let lastAttributeValue = selectedAttributesList.pop();

          let obj = {
            key: lastAttributeValue?.name,
            value: lastAttributeValue?.id
          }

          this.onRemoveAttribute(obj);

        }

        if (selectedSubCategory !== null && selectedSubCategory !== undefined) {
          this.onSubCategorySelect(selectedSubCategory, 'back');
        }

        if (selectedAttributesList.length !== 0) {

          for (let i = 0; i < selectedAttributesList.length; i++) {

            setTimeout(() => {

              this.onFilteredData(selectedAttributesList[i].datas, selectedAttributesList[i].options, selectedAttributesList[i].name, selectedAttributesList[i].id, 'filter');

            }, 500);

          }
        } else {
          localStorage.removeItem('selected-attribute-array');
          this.filterAttributeArray = [];
        }
      } else {
        if (selectedSubCategory !== null && selectedSubCategory !== undefined) {
          if (type !== 'another') {
            this.getCurrent(undefined);
          } else {
            this.onSubCategorySelect(selectedSubCategory, 'back');
          }
        } else {
          this.getCurrent(undefined);
        }
      }
    } else {
      if (selectedSubCategory !== null && selectedSubCategory !== undefined) {
        if (type !== 'another') {
          this.getCurrent(undefined);
        } else {
          this.onSubCategorySelect(selectedSubCategory, 'back');
        }
      } else {
        this.getCurrent(undefined);
      }
    }
  }

  backClicked() {
    // let splitUrl = this.router.url.split("/")
    // let check = this.gallaeryDetailArr.some((element) => (element.id == splitUrl[2] && element.key == splitUrl[3]) )
    // console.log("check",check)
    // console.log("router",splitUrl,"galery",this.gallaeryDetailArr)
    this.perPage = 8;

    console.log("page", this.p);


    let selectedSubCategory = JSON.parse(localStorage.getItem('selected-sub-category'));
    let selectedAttributesList = JSON.parse(localStorage.getItem('selected-attribute-array'));
    // console.log("selected sub category back click", selectedSubCategory);
    // console.log("selected attribulte category back click", selectedAttributesList);
    this.resultObj = {};
    this.valueObj = {};
    this.idObj = {};
    this.labelOptions = [];
    this.labelIds = [];
    let type = 'current';

    if (selectedSubCategory != null || selectedAttributesList != null) {
      this.handleToGetFilterValuesBack(selectedSubCategory, selectedAttributesList, type);
    }
    else {
      this.p = JSON.parse(localStorage.getItem("page"))
      if (this.p >= 1 && this.p != null) {
        if (this.p == 1) {
          this._location.back()
        }
        else {
          this.changePage(this.p - 1);
        }
      }
      else {
        this._location.back()
      }

    }

  }
  getFontProductTitle() {
    this._dataservice.getFontsProduct().subscribe((res) => {
      console.log("hresponse", res);
      if (res["data"].length !== 0) {
        this.fonts_array_data = res["data"][0];
      }

    })
  }
  ngOnInit() {
    this.loadInitialData();
    console.log("ngproductListPart", this.productListPart)
    this.getFontProductTitle();
    this.isloggedIn = localStorage.getItem('token') ? true : false;
    console.log("islog", this.isloggedIn)
    /** dropdown **/

    // if (this.typeOfRoute !== 'current' && this.typeOfRoute !== undefined) {
    //   this.handleToGetFilterValuesBack(this.backSelectedCategory, this.backSelectedSubCategory, this.backSelectedAttributes, this.typeOfRoute);
    // }

    this.userid = parseInt(localStorage.getItem('user_id'))
  }

  loadInitialData() {
    this.vertical_id = this.route.snapshot.params.id;
    this.selectedValue = this.vertical_id;
    this.pro_name = this.route.snapshot.params.p_name;
    this.catagoryName = this.route.snapshot.params.name;
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      // do your task for before route
      // console.log("changes date")
      return false;
    }
    this.attributeDropDown(this.vertical_id);

    this.user_id = localStorage.getItem('user_id')

    if (!this.tokenService._get_token()) {
      if (this.typeOfRoute !== 'another') {
        this.getNonUser(this.vertical_id);
      }


    } else {
      this.changeDetect.detectChanges()
      if (this.typeOfRoute !== 'another') {
        this.getProductList(this.vertical_id);
      }

    }

    this.p = localStorage.getItem('page') ? localStorage.getItem('page') : 1
    this.sizeDropdown(this.vertical_id);
    this.materialDropdown(this.vertical_id);

    this.getSubcategory(this.vertical_id);
    console.log("getsubcategory", this.vertical_id)
    this.subscription = this._dataservice.currentMessage.subscribe(msg => {

      this.message = msg;
    }
    )
    /** dropdown **/

    if (this.typeOfRoute !== 'current') {
      console.log("handletogetfilter")
      this.handleToGetFilterValuesBack(this.backSelectedSubCategory, this.backSelectedAttributes, this.typeOfRoute);

    }
  }


  // categoryDropdown() {
  //   this._dataservice.get_vertical_menu().subscribe((res: any) => {

  //     this.changeDetect.markForCheck()

  //     // console.log("catrgory vertival product dropdown reponse",res);

  //     if (res?.data) {

  //       this.catagoryListVerticalIds = res?.data;

  //       this.selectedItem = this.catagoryName;

  //       localStorage.setItem('category-list', JSON.stringify(this.catagoryListVerticalIds));

  //       if(this.typeOfRoute !== 'another') {
  //         this.filterAttributeArray = [];
  //         localStorage.removeItem('selected-sub-category');

  //         localStorage.removeItem('selected-attribute-array');
  //       }

  //       // console.log("category products list", this.catagoryListVerticalIds)
  //     } else {

  //       this.catagoryListVerticalIds = []

  //     }

  //     // console.log("array", this.catagoryListVerticalIds);

  //     this.catagoryListVerticalIds.forEach((item: any) => {
  //       if (item.id == this.vertical_id) {
  //         this.selectedValue = item
  //         // console.log("check", this.selectedValue)
  //         localStorage.setItem('selected-category-list', JSON.stringify(this.selectedValue));
  //       }
  //     });
  //   })









  //   // this._dataservice.get_vertical_menu().subscribe((res: any) => {

  //   //   this.changeDetect.markForCheck()

  //   //   // console.log("catrgory vertival product dropdown reponse",res);

  //   //   if (res?.data) {

  //   //     this.catagoryListVerticalIds = res?.data;

  //   //     this.selectedItem = this.catagoryName;

  //   //     this.filterAttributeArray = [];

  //   //     localStorage.setItem('category-list', JSON.stringify(this.catagoryListVerticalIds));

  //   //     localStorage.removeItem('selected-sub-category');

  //   //     localStorage.removeItem('selected-attribute-array');

  //   //     // console.log("category products list", this.catagoryListVerticalIds)
  //   //   } else {

  //   //     this.catagoryListVerticalIds = []

  //   //   }

  //   //   // console.log("array", this.catagoryListVerticalIds);

  //   //   this.catagoryListVerticalIds.forEach((item: any) => {
  //   //     if (item.id == this.vertical_id) {
  //   //       this.selectedValue = item
  //   //       console.log("check", this.selectedValue)
  //   //       localStorage.setItem('selected-category-list', JSON.stringify(this.selectedValue));
  //   //     }
  //   //   });
  //   // })

  // }

  attributeDropDown(verticalId) {
    let data = { vertical_id: verticalId }

    this.catagoryDropDownMain = [];
    if (this.typeOfRoute !== 'another') {
      this.filterAttributeArray = [];
      localStorage.removeItem('selected-attribute-array');
    }
    this._dataservice.catagorydropdown(data).subscribe((res: any) => {
      console.log("attribute dropdowns response:", res);
      if (res.data) {
        this.catagoryDropDownMain = res.data;
        this.catagoryDropDownMain = this.catagoryDropDownMain.map((list, i) => {
          return {
            ...list,
            "organizedId": i
          }
        })
        console.log("attribute dropdown list:", this.catagoryDropDownMain)
        this.valueObj = {};
        this.idObj = {};

        this.catagoryDropDownMain.forEach((item, idx) => {
          console.log("yye", item)
          this.valueObj[item.label.name] = ""
          this.idObj[item.label.id] = ""
          this.valueObj = {};
          this.idObj = {};
          console.log("Value", this.valueObj)
        })
      }
    })
  }

  sizeDropdown(verticalId) {

    var reqObj = {
      "vertical_id": verticalId
    }
    this._dataservice.getSizeList(reqObj).subscribe((res: any) => {
      // console.log("res size", this.sizeList);
      // return false
      if (res?.data) {
        this.sizeList = res.data ? res.data : [];
      } else if (res.message == "No size list") {
        this.sizeList = [];
      }

    },
      err => {
        console.log(err)
      })
  }

  materialDropdown(verticalId) {
    var reqObj = {
      "vertical_id": verticalId
    }
    this._dataservice.getMaterialList(reqObj).subscribe((res: any) => {
      if (res.data) {

        this.materialList = res.data;
      } else if (res.message == "No Material list") {

        this.materialList = [];
      }

    },
      err => {
        console.log(err)
      })
  }

  getNonUser(verticalId) {
console.log('Came to vertigf',verticalId)
    this.loading = true;
    var reqObj = {
      "vertical_id": verticalId
    }
    this.productList = [];
    this.productListPart = [];
    this.showMasonry = false;
    this._dataservice.getListNon(reqObj).subscribe((res: any) => {
      if (res.data?.products) {
        this.loadedAll = false;
        this.perPage = 8;
        this.productList = res.data.products;
        this.productListPart = this.productList.slice(0, this.productList.length);
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = true;
        // this.p=JSON.parse(localStorage.getItem("page"))
        // this.handleToCheckShowMore(this.p,this.productListPart.length)
        let product_length = this.productListPart.length;
        console.log("inner product", this.p, product_length);

        console.log("cal", (product_length - (this.p) * 8));
        if (this.showMoreFromLocalStorage !== null && this.showMoreFromLocalStorage === 'true') {
          console.log("showMoreFromLocalStorage", this.showMoreFromLocalStorage, typeof (this.showMoreFromLocalStorage));
          // this.isNotShownMore = true;
          // this.loadMoreProd(this.productListPart)
        }
        else {
          if ((product_length - (this.p) * 8) < 0) {
            this.isNotShownMore = false;
            console.log("this is refresh conent");

          }
          if (product_length <= 8) {
            this.isNotShownMore = false;
            console.log("product length lesser", product_length);
          }
          else {
            console.log("product_length", product_length);

            this.isNotShownMore = true;
            console.log("value greater");

          }
        }
      } else {
        this.productListPart = [];
        this.productList = [];
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = false;
        this.isNotShownMore = false;
      }
      // this.p= sessionStorage.getItem('page')?sessionStorage.getItem('page'):1;
      // this.currentPage= sessionStorage.getItem('page')?sessionStorage.getItem('page'):1;
    })


    // this.selectedSubItemName = "";
    // this.loading = true;
    // var reqObj = {
    //   "vertical_id": verticalId
    // }
    // this.productList = [];
    // this.productListPart = [];
    // this._dataservice.getListNon(reqObj).subscribe((res: any) => {
    //   if (res.data?.products) {
    //     this.loadedAll = false;
    //     this.productList = res.data.products;
    //     this.productListPart = this.productList.slice(0,this.productList.length);
    //     // this.masonry.reloadItems();
    //     this.loading = false;
    //     let product_length = this.productListPart.length;
    //     console.log("inner product",this.p,product_length);

    //     console.log("cal",(product_length - (this.p)*8));
    //     if((product_length - (this.p)*8) < 0)
    //     {
    //       this.isNotShownMore = false;
    //       console.log("this is refresh conent");

    //     }
    //     if(product_length <= 8)
    //     {
    //       this.isNotShownMore = false;
    //     console.log("product length lesser",product_length);
    //     }
    //     else
    //     {
    //       console.log("value greater");

    //     }

    //   } else {
    //     this.productListPart = [];
    //     this.productList = [];
    //     // this.masonry.reloadItems();
    //     this.loading = false;
    //   }
    //   // this.p= sessionStorage.getItem('page')?sessionStorage.getItem('page'):1;
    //   // this.currentPage= sessionStorage.getItem('page')?sessionStorage.getItem('page'):1;
    // })
  }

  getSubcategory(verticalId) {
    this.subCatagoryListArray = [];
    this.isShowMore = false;
    this.isNotShownMore = true;
    this._dataservice.getCatagoryList(verticalId).subscribe((res: any) => {
      // console.log("sub catogery list response",res);
      if (res?.data) {
        this.subCatagoryListArray = res?.data;
        localStorage.setItem('sub-category', JSON.stringify(this.subCatagoryListArray));
        console.log("sub category list", this.subCatagoryListArray);

        // localStorage.removeItem('selected-sub-category');
      }
      else {
        this.subCatagoryListArray = [];
        // localStorage.removeItem('selected-sub-category')
      }
      // console.log("sub cate", res)
    })


    // this.subCatagoryListArray = [];
    // this.isShowMore = false;
    // this.isNotShownMore = true;
    // this._dataservice.getCatagoryList(verticalId).subscribe((res: any) => {
    //   // console.log("sub catogery list response",res);
    //   if (res?.data) {
    //     this.subCatagoryListArray = res?.data;
    //     localStorage.setItem('sub-category', JSON.stringify(this.subCatagoryListArray));
    //     // localStorage.removeItem('selected-sub-category');
    //   }
    //   else {
    //     this.subCatagoryListArray = [];
    //     // localStorage.removeItem('selected-sub-category')
    //   }
    //   // console.log("sub cate", res)
    //   // console.log("sub-category-list-array",this.subCatagoryListArray);  
    // })
  }
  revoke() {
  }
  filterObj: any = {};
  filterAttributeArray: any = [];
  // last chaneg 03 oct 22
  onFilteredData(datas, options, name, id, whereItCame) {

    // console.log("productListPart",this.productListPart)
    // console.log("filter attribute",this.filterAttributeArray)

    // console.log("on filter data datas", datas);
    // console.log('on filter data  name', name);
    // console.log('on filter data  id', id);


    this.isShowMore = false;
    this.isNotShownMore = true;
    this.filterObj = {
      'datas': datas,
      'options': options,
      'name': name,
      'id': id
    }
    // console.log("on filter data  options", this.filterObj);
    let checkAlreadyExist = this.filterAttributeArray.some(list => list?.id === this.filterObj?.id);
    // console.log("check",checkAlreadyExist);

    if (checkAlreadyExist) {

      this.filterAttributeArray.forEach((element, index) => {

        if (element?.id === this.filterObj?.id) {

          this.filterAttributeArray.splice(index, 1, this.filterObj);

          localStorage.setItem('selected-attribute-array', JSON.stringify(this.filterAttributeArray));
        }

      })

    } else {

      this.filterAttributeArray.push(this.filterObj);

      localStorage.setItem('selected-attribute-array', JSON.stringify(this.filterAttributeArray));

    }

    console.log("on filter data attribute array:", this.filterAttributeArray);
    // this.filterAttributeArray = this.filterAttributeArray.sort((firstSortValue, secondSortValue) => { 
    //   return - (secondSortValue.id - firstSortValue.id);
    // });

    // console.log("filter ovject after filete", this.filterObj);


    this.valueObj[name] = options;
    console.log("value obj", this.valueObj);

    console.log("id", id, datas.label.id);

    this.idObj[id] = datas.label.id;
    // this.idObj.push(datas.label.id)

    // console.log("idobj",this.idObj[id]);

    var arrayval = Object.values(this.valueObj)
    // console.log("arr value", arrayval)

    var arrayId = Object.values(this.idObj)

    console.log("array id", arrayId);

    this.labelOptions = arrayval.filter((val: any) => val != "")
    this.labelIds = arrayId.filter((val: any) => val != "")
    console.log("label ides", this.labelIds);

    // console.log("resukt", this.resultObj)
    // localStorage.setItem("selected-options", JSON.stringify(this.labelOptions));
    // console.log("datasss", this.labelIds)
    console.log("optionsss", this.labelOptions)

    this.resultObj = {}
    // if (this.labelIds && this.labelOptions) {
    //   for (var i = 0; i < this.labelIds.length; i++) {


    //     this.resultObj[this.labelOptions[i]] = this.labelIds[i];
    //   }
    // }

    if (this.filterAttributeArray.length != 0) {
      let resultkey: any;
      for (let i = 0; i < this.filterAttributeArray.length; i++) {
        resultkey = Object.values(this.filterAttributeArray[i])
        console.log("resultkey", resultkey[1]);
        this.resultObj[resultkey[1]] = resultkey[3];
      }
    }
    // console.log("final filter result object",this.resultObj)
    console.log("resulted object", this.resultObj);

    let obj = {
      "vertical_id": this.vertical_id,
      "label_id": this.labelIds,
      "label_option": this.labelOptions,
    }

    if (this.subcategoryId) {
      obj['sub_cat_id'] = this.subcategoryId
    }
    if (this.usermail != null) {
      obj['email'] = this.usermail;
    }

    this.handleFilterAttributeData(obj);
   
    // console.log("datas", datas);
    // console.log('name', name);
    // console.log('id', id);
    // console.log("filter options", options);
    // this.isShowMore = false;
    // this.isNotShownMore = true;
    // this.filterObj = {
    //   'datas': datas,
    //   'options': options,
    //   'name': name,
    //   'id': id
    // }

    // console.log("filter array:", this.filterAttributeArray);

    // if (whereItCame !== 'filter') {

    //   let checkAlreadyExist = this.filterAttributeArray.some(list => list?.id === this.filterObj?.id);
    //   // console.log("check",checkAlreadyExist);

    //   if (checkAlreadyExist) {

    //     this.filterAttributeArray.forEach((element, index) => {

    //       if (element?.id === this.filterObj?.id) {

    //         this.filterAttributeArray.splice(index, 1, this.filterObj);

    //         localStorage.setItem('selected-attribute-array', JSON.stringify(this.filterAttributeArray));
    //       }

    //     })

    //   } else {

    //     this.filterAttributeArray.push(this.filterObj);

    //     localStorage.setItem('selected-attribute-array', JSON.stringify(this.filterAttributeArray));

    //   }

    //   // this.filterAttributeArray = this.filterAttributeArray.sort((firstSortValue, secondSortValue) => { 
    //   //   return - (secondSortValue.id - firstSortValue.id);
    //   // });
    // }

    // console.log("filter ovject after filete", this.filterObj);

    // this.valueObj[name] = options;

    // console.log("value object after filter", this.valueObj);

    // this.idObj[id] = datas.label.id;
    // // console.log("name  value", this.valueObj[name])
    // var arrayval = Object.values(this.valueObj)
    // // console.log("arr value", arrayval)
    // var arrayId = Object.values(this.idObj)
    // this.labelOptions = arrayval.filter((val: any) => val != "")
    // this.labelIds = arrayId.filter((val: any) => val != "")
    // console.log("resukt", this.resultObj)
    // // localStorage.setItem("selected-options", JSON.stringify(this.labelOptions));
    // console.log("datasss", this.labelIds)
    // console.log("optionsss", this.labelOptions)

    // this.resultObj = {}
    // if (this.labelIds && this.labelOptions) {
    //   for (var i = 0; i < this.labelIds.length; i++) {
    //     this.resultObj[this.labelOptions[i]] = this.labelIds[i];
    //   }
    // }

    // // console.log("resulted object",this.resultObj);
    // let obj = {
    //   "vertical_id": this.vertical_id,
    //   "label_id": this.labelIds,
    //   "label_option": this.labelOptions,
    // }

    // if (this.subcategoryId) {
    //   obj['sub_cat_id'] = this.subcategoryId
    // }
    // this.handleFilterAttributeData(obj);
  }


  handleFilterAttributeData(obj) {

    this.productList = [];
    this.productListPart = [];
    this.showMasonry = false;
    this._dataservice.getFilteredDropdownData(obj).subscribe((res: any) => {
      // console.log("filtered response", res);
      if (res.status !== 'Failed') {
        if (res.data.products) {
          this.perPage = 8;
          this.productList = res?.data?.products;
          this.productListPart = this.productList.slice(0, this.productList.length);
          //  this.masonry.reloadItems();
          // console.log("list of product list", this.productListPart);
          const productid = this.productList.map(item => item.id);

          let data_prod={
            "vertical_id": this.vertical_id,
            "label_id": this.labelIds,
            "label_option": this.labelOptions,
            "product_id":productid
      
          }
          this.attributeDropDownTest(data_prod)
          this.changePage(1);
          console.log("after productListPart", this.productListPart)
          this.loading = false;

          this.showMasonry = true
          if ((this.productListPart.length) <= 8) {
            this.isNotShownMore = false;

          }
          else {
            console.log("last productListPart", this.productListPart)
          }
          // this.changeDetect.detectChanges();
        }
      } else {
        this.perPage = 8;
        this.productListPart = [];
        this.productList = [];
        this.loading = false;
        this.showMasonry = false;
        this.isNotShownMore = false;
      }
      this.loadedAll = false
    }, err => {
      console.log("filter error", err)
      this.loading = false;
      this.productListPart = [];
      this.productList = [];
      // this.masonry.reloadItems();
    })

    // this.productList = [];
    // this.productListPart = [];
    // this._dataservice.getFilteredDropdownData(obj).subscribe((res: any) => {
    //   // console.log("filtered response",res);
    //   if(res.status !== "Failed"){
    //     if (res.data.products) {
    //       this.productList = res?.data?.products;
    //       this.productListPart = this.productList.slice(0,this.productList.length);
    //     //  this.masonry.reloadItems();
    //       console.log("list of product list", this.productListPart);
    //       this.loading = false;
    //       if(this.productListPart.length <= 8)
    //       {
    //         this.isNotShownMore = false;

    //       }
    //       // this.changeDetect.detectChanges();
    //     }
    //     this.loadedAll = false

    //   }
    //  else{

    //   this.productListPart = [];
    //   this.productList = [];
    //   // this.masonry.reloadItems();

    //   this.loading = false;
    //   this.toastr.error(res.message); 
    //   }

    // }, err => {
    //   this.loading = false;
    //   this.productListPart = [];
    //    this.productList = [];
    //   // this.masonry.reloadItems();
    // })
  }

  mergeArrayObjects(arr1, arr2) {
    return arr1.map((item, i) => {
      return Object.assign({}, item, arr2[i])
    })
  }

  onRemoveAttribute(label) {
    console.log("remove label", label);

    // console.log("productListPart cancel",this.productListPart,this.productListPart.length)
    // console.log("on remove attribule lable", label.key, label.value);
    this.isShowMore = false;
    this.isNotShownMore = true;
    this.filterAttributeArray = this.filterAttributeArray.filter((list) => {
      if (list?.id !== label.value) {
        return list;
      }
    });

    // console.log("on remove filter attribute array", this.filterAttributeArray);

    if (this.filterAttributeArray.length !== 0) {

      localStorage.setItem('selected-attribute-array', JSON.stringify(this.filterAttributeArray));

    } else {

      localStorage.removeItem('selected-attribute-array');

    }

    // console.log("id obj", this.idObj);
    // console.log("val obj", this.valueObj);
    // console.log("before result obj", this.resultObj);

    delete this.idObj[label.value];
    delete this.valueObj[label.key];
    delete this.resultObj[label.key];
    delete this.resultObj[label.value];
    // console.log("after resulted object",this.resultObj)
    // console.log("after id obj", this.idObj)
    // console.log("after  value obj", this.valueObj)
    // console.log("after  result obj", this.resultObj)
    this.labelOptions = this.labelOptions.filter(item => item !== label.key)
    this.labelIds = this.labelIds.filter(item => item !== label.value)
    // console.log("l options ", this.labelOptions)
    // console.log("l ids ", this.labelIds)
    // return false
    // console.log('label opt', this.labelOptions);
    // localStorage.setItem("selected-options", JSON.stringify(this.labelOptions));
    if (!this.labelIds.length) {
      Object.keys(this.idObj).forEach(key => {
        delete this.idObj[key];
      })
      Object.keys(this.valueObj).forEach(key => {
        delete this.valueObj[key];
      })
      // Object.keys(this.resultObj).forEach(key => {
      //   delete this.resultObj[key];
      // })
    }
    // console.log("l options ", this.labelOptions,this.labelOptions.length)
    if (this.labelOptions.length !== 0) {
      var obj;
      obj = {
        "vertical_id": this.vertical_id,
        "label_id": this.labelIds,
        "label_option": this.labelOptions,
      }
      if (this.subcategoryId) {
        obj["sub_cat_id"] = this.subcategoryId
      }
      if (this.usermail != null) {
        obj['email'] = this.usermail;
      }
      this.productList = [];
      this.productListPart = [];
      this.showMasonry = false
      this._dataservice.getFilteredDropdownData(obj).subscribe((res: any) => {
        if (res.status == "Failed") {
          console.log("loading failed");

          this.productListPart = [];
          this.productList = [];
          //  this.masonry.reloadItems();
          this.loading = false;
          this.showMasonry = true

        } else {
          if (res.data.products) {
            this.perPage = 8;
            this.productList = res?.data?.products;
            const productid = this.productList.map(item => item.id);

            let data: any = {
              "vertical_id": this.vertical_id,
              "sub_cat_id": this.subcategoryId,
              "product_id": productid,
              "label_id": this.labelIds,
            }
            this.attributeDropDownTest(data)

            this.changePage(1);
            this.productListPart = this.productList.slice(0, this.productList.length);
            // this.masonry.reloadItems();
            // console.log("loading done",this.productListPart)
            this.loading = false;
            this.showMasonry = true;
            if ((this.productListPart.length) <= 8) {
              this.isNotShownMore = false;
            }

          }
        }
      }, err => {
        this.loading = false;
      });
    } else if (this.subcategoryId) {
      // console.log("subcategory id",this.subcategoryId)
      var sendObj = {}
      sendObj['vertical_id'] = parseInt(this.vertical_id),
        sendObj["sub_cat_id"] = this.subcategoryId
      if (this.usermail != null) {
        sendObj['email'] = this.usermail;
      }
      this.productList = [];
      this.productListPart = [];
      this.showMasonry = false
      this._dataservice.getFilteredDropdownData(sendObj).subscribe((res: any) => {
        console.log('Inside sub cate',res)
        if (res.status == "Failed") {
          this.productListPart = [];
          this.productList = [];
          // this.masonry.reloadItems();
          this.loading = false;
          this.showMasonry = false;
        } else {
          if (res.data.products) {
            this.perPage = 8;
            this.productList = res?.data?.products;
            const productid = this.productList.map(item => item.id);
            let data: any = {
              "vertical_id": this.vertical_id,
              "sub_cat_id": this.subcategoryId,
              "product_id": productid,
              "label_id": this.labelIds,
            }
            this.attributeDropDownTest(data)
            this.productListPart = this.productList.slice(0, this.productList.length);
            // this.masonry.reloadItems();            
            this.loading = false;
            this.showMasonry = true;
            if ((this.productListPart.length) <= 8) {
              this.isNotShownMore = false;
            }

          }
        }
      }, err => {
        console.log("error handle here")
        this.loading = false;
      })
    }
    else if (!this.subcategoryId) {

      if (!this.tokenService._get_token()) {
        console.log('Came to here ')
        let data: any = {
          "vertical_id": this.vertical_id,
          "sub_cat_id": this.subcategoryId,
          "product_id": [],
          "label_id": this.labelIds,
        }
        this.attributeDropDownTest(data)
        this.getNonUser(this.vertical_id);

      } else {
        this.changeDetect.detectChanges();
        console.log('sdsdfds')
        this.getProductList(this.vertical_id);
      }
    }




    // // debugger
    // console.log("lable", label.key, label.value);
    // this.isShowMore = false;
    // this.isNotShownMore = true;
    // this.filterAttributeArray = this.filterAttributeArray.filter((list) => {
    //   if (list?.id !== label.value) {
    //     return list;
    //   }
    // });

    // console.log("filter array", this.filterAttributeArray);

    // if (this.filterAttributeArray.length !== 0) {

    //   localStorage.setItem('selected-attribute-array', JSON.stringify(this.filterAttributeArray));

    // } else {

    //   localStorage.removeItem('selected-attribute-array');

    // }

    // // console.log("id obj", this.idObj);
    // // console.log("val obj", this.valueObj);
    // // console.log("result obj", this.resultObj);

    // delete this.idObj[label.value];
    // delete this.valueObj[label.key];
    // delete this.resultObj[label.key];
    // delete this.resultObj[label.value];
    // // console.log("after id obj", this.idObj)
    // // console.log("after  value obj", this.valueObj)
    // // console.log("after  result obj", this.resultObj)
    // this.labelOptions = this.labelOptions.filter(item => item !== label.key)
    // this.labelIds = this.labelIds.filter(item => item !== label.value)
    // console.log("l options ", this.labelOptions)
    // console.log("l ids ", this.labelIds)
    // // return false
    // console.log('label opt', this.labelOptions);
    // // localStorage.setItem("selected-options", JSON.stringify(this.labelOptions));
    // if (!this.labelIds.length) {
    //   Object.keys(this.idObj).forEach(key => {
    //     delete this.idObj[key];
    //   })
    //   Object.keys(this.valueObj).forEach(key => {
    //     delete this.valueObj[key];
    //   })
    //   // Object.keys(this.resultObj).forEach(key => {
    //   //   delete this.resultObj[key];
    //   // })
    // }
    // if (this.labelOptions.length && this.labelOptions.length) {
    //   var obj;
    //   obj = {
    //     "vertical_id": this.vertical_id,
    //     "label_id": this.labelIds,
    //     "label_option": this.labelOptions,

    //   }
    //   if (this.subcategoryId) {
    //     obj["sub_cat_id"] = this.subcategoryId
    //   }

    //   this.productList = [];
    //   this.productListPart = [];
    //   this._dataservice.getFilteredDropdownData(obj).subscribe((res: any) => {
    //     if (res.status == "Failed") {
    //       this.productListPart = [];
    //       this.productList = [];
    //     //  this.masonry.reloadItems();
    //       this.loading = false;
    //     } else {
    //       if (res.data.products) {
    //         this.productList = res?.data?.products;
    //         this.productListPart = this.productList.slice(0,this.productList.length);
    //         // this.masonry.reloadItems();
    //         this.loading = false;
    //         if(this.productListPart.length<=8)
    //         {
    //           this.isNotShownMore = false;
    //         }
    //       }
    //     }
    //   }, err => {
    //     this.loading = false;
    //   });
    // } else if (this.subcategoryId) {
    //   var sendObj = {}
    //   sendObj['vertical_id'] = parseInt(this.vertical_id),
    //     sendObj["sub_cat_id"] = this.subcategoryId

    //   this.productList = [];
    //   this.productListPart = [];
    //   this._dataservice.getFilteredDropdownData(sendObj).subscribe((res: any) => {
    //     if (res.status == "Failed") {
    //       this.productListPart = [];
    //       this.productList = [];
    //       // this.masonry.reloadItems();
    //       this.loading = false;
    //     } else {
    //       if (res.data.products) {
    //         this.productList = res?.data?.products;
    //         this.productListPart = this.productList.slice(0,this.productList.length);
    //           // this.masonry.reloadItems();            
    //         this.loading = false;
    //         if(this.productList.length <=8 )
    //         {
    //           this.isNotShownMore = false;
    //         }
    //       }
    //     }
    //   }, err => {
    //     this.loading = false;
    //   })
    // }
    // else if (!this.subcategoryId) {

    //   if (!this.tokenService._get_token()) {
    //     this.getNonUser(this.vertical_id);



    //   } else {
    //     this.changeDetect.detectChanges()
    //     this.getProductList(this.vertical_id);
    //   }
    // }

  }

  //radio buttons
  onInputChange(event) {
    var sortvalue;
    this.checkedstore = event.target.checked

    if (event.target.value == 1) {
      sortvalue = "popular"
    }
    else {
      sortvalue = "recent"
    }

    var obj = {
      'catagory_id': this.checkboxArray,
      'sort': sortvalue
    }

    this.storeSelectedValues = obj
    console.log("pbject", obj)

  }



  OnReset() {
    this.getProductList(this.vertical_id)
    $('body').find('.tab_menus').removeClass('active')
  }

  OnAddCart(productId) {

    var obj = {
      'user_id': parseInt(this.user_id),
      'product_id': parseInt(productId),
      'wish_catagory_id': this.wish_catagory_id
    }


    this._dataservice.updateWishList(obj).subscribe((res: any) => {

      if (res) {
        this.getProductList(this.vertical_id);
        this.toastr.success(res.message, 'Success');
        $('body').find('.tab_menus').removeClass('active')
      }

    })


  }

  oncontrol() {
    if ($(window).width() < 991) {

      $('.clicked').hide()

    }
  }
  toggleShow(label: string) {
    if (label == "catgory") {
      this.isShownCat = !this.isShownCat;
      this.isShowSubCat = false;
      this.isShowAttribute = false;
    }
    else if (label == 'sub_catgory') {
      this.isShowSubCat = !this.isShowSubCat;
      this.isShownCat = false;
      this.isShowAttribute = false
    }
    else if (label == 'attributes') {
      this.isShowAttribute = !this.isShowAttribute;
      this.isShownCat = false;
      this.isShowSubCat = false
    }

  }
  getCurrent(val?: any) {

    console.log("get current value", val, "ver_id", this.selectedValue);
    this.vertical_id = this.selectedValue;
    localStorage.removeItem('selected-sub-category');
    localStorage.removeItem('selected-attribute-array')
    // localStorage.removeItem('selected-options');
    this.selectedSubItemName = "";
    this.isShowMore = false;
    this.isNotShownMore = true;
    this.labelIds = [];
    this.labelOptions = [];
    this.subcategoryId = null
    this.resultObj = {}
    this.idObj = {};
    this.valueObj = {};
    this.filterAttributeArray = [];
    console.log("result object", this.resultObj)
    var response;
    if (this.tokenService._get_token()) {

      response = this._dataservice.get_productsById(this.vertical_id)
      // console.log("get current token value", response);

    }
    else {
      var reqObj = {
        "vertical_id": this.vertical_id
      }
        this.attributeDropDownTest(reqObj)
      response = this._dataservice.getListNon(reqObj)
      // console.log("get current un token value", response);
    }
    this.productList = [];
    this.productListPart = [];
    this.showMasonry = false;
    response.subscribe((res: any) => {
      if (res.data) {
        this.loadedAll = false;
        this.productList = res.data.products;
        this.productListPart = this.productList.slice(0, this.productList.length);
        // this.masonry.reloadItems();        
        this.loading = false;
        this.showMasonry = true;
        if ((this.productListPart.length) <= 8) {
          this.isNotShownMore = false;
        }
      }
      else {
        this.productListPart = [];
        this.productList = [];
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = false;

      }
      console.log("after result object", this.resultObj)
    }, err => {
      console.log("Error", err)
    })

    // console.log("get current value", val);
    // localStorage.removeItem('selected-sub-category');
    // localStorage.removeItem('selected-attribute-array')
    // // localStorage.removeItem('selected-options');
    // // this.selectedSubItemName = "";
    // this.isShowMore = false;
    // this.isNotShownMore = true;
    // this.labelOptions = [];
    // this.subcategoryId = null
    // this.resultObj = {}
    // var response;
    // if (this.tokenService._get_token()) {

    //   response = this._dataservice.get_productsById(this.vertical_id)
    //   console.log("get current token value", response);

    // }
    // else {
    //   var reqObj = {
    //     "vertical_id": this.vertical_id
    //   }

    //   response = this._dataservice.getListNon(reqObj)
    //   console.log("get current un token value", response);
    // }
    // this.productList = [];
    // this.productListPart = [];
    // response.subscribe((res: any) => {
    //   if (res.data) {
    //     this.loadedAll = false;
    //     this.productList = res.data.products;
    //     this.productListPart = this.productList.slice(0,this.productList.length);
    //           // this.masonry.reloadItems();        
    //     this.loading = false;
    //     if(this.productListPart.length <= 8)
    //     {
    //       this.isNotShownMore = false;
    //     }
    //   }
    //   else {        
    //     this.productListPart = [];
    //     this.productList = [];
    //     // this.masonry.reloadItems();
    //     this.loading = false
    //   }
    // }, err => {
    //   console.log("Error", err)
    // })

  }
  get data(): string {
    return this._dataservice.sharedData
  }

  set data(value: string) {
    this._dataservice.sharedData = value
  }

  //catagory select option
  // onCatagorySelected(e?: any, whenItCame?) {
  //   console.log("selected category", e);
  //   localStorage.removeItem('page')
  //   this.p = 1;
  //   this.currentPage = 1;
  //   // this.isShownCat=false
  //   this.filterAttributeArray = [];
  //   if (whenItCame !== 'back') {
  //     localStorage.setItem('selected-category-list', JSON.stringify(e));
  //     localStorage.removeItem('selected-attribute-array');
  //     localStorage.removeItem('selected-sub-category');
  //   }
  //   // console.log("selected category", e);
  //   this.selectedProd = e;
  //   this._location.go(`/categories/${e.id}/${e.name}`);
  //   // this.myHeadComp.receiveItem=e.name
  //   this._dataservice.changeMessage(e.name)
  //   this.selectedSubItemName = "";
  //   this.resultObj = {}
  //   this.selectedItem = e;
  //   this.valueObj = {};
  //   this.idObj = {};
  //   this.subcategoryId = "";
  //   this.labelIds = [];
  //   this.labelOptions = [];
  //   this.loading = true;
  //   this.vertical_id = e.id;

  //   if (whenItCame !== 'back') {
  //     this.getSubcategory(this.vertical_id);
  //   }
  //   // this.sizeDropdown();

  //   this.catagoryName = e.name;
  //   if (this.catagoryName == "CONCEPT" || this.catagoryName == "LEATHER") {
  //     this.hideGender = true
  //   } else {
  //     this.hideGender = false
  //   }
  //   var response;
  //   this.attributeDropDown(this.vertical_id)
  //   if (this.tokenService._get_token()) {
  //     response = this._dataservice.get_productsById(this.vertical_id)
  //   }
  //   else {
  //     var reqObj = {
  //       "vertical_id": this.vertical_id
  //     }
  //     response = this._dataservice.getListNon(reqObj)
  //   }
  //   this.productList = [];
  //   this.productListPart = []
  //   response.subscribe((res: any) => {
  //     if (res.data) {
  //       this.loadedAll = false;
  //       this.productList = res.data.products;
  //       this.productListPart = this.productList;
  //       this.loading = false;
  //       this.changeDetect.detectChanges()
  //     }
  //     else {

  //       this.loading = false;
  //       this.productList = [];
  //       this.productListPart = []
  //       this.changeDetect.detectChanges()
  //     }

  //   }, err => {
  //     console.log("Error", err)
  //   })

  // }


  isObject(): boolean {
    return typeof this.selectedValue === "object"
  }
  // catagory gender option

  //filter by button click function
  onHandleFilter() {
    // alert(this.unitTrustsPnl)
    if (this.checkboxArray.length === 0) {
      this.toastr.warning("Please Select Catagory to Filter");
      // this.loaded=true;

    }
    else if (Object.keys(this.storeSelectedValues).length == 0 && this.checkedstore === true) {
      this.toastr.warning("Please Select any one Catagory to Filter");
    }

    else {

      this.productList = [];
      this.productListPart = [];
      this._dataservice.categoryFilter(this.storeSelectedValues).subscribe((responses: any) => {
        console.log("res val", responses)
        if (responses) {
          this.loadedAll = false;
          this.productList = responses.data.products;
          this.productListPart = this.productList.slice(0, this.productList.length);
          if ((this.productListPart.length) <= 8) {
            this.isNotShownMore = false;
          }
          // this.masonry.reloadItems();
        }
        else {
          if (!responses?.data) {
            this.productListPart = [];
            this.productList = [];
            // this.masonry.reloadItems();
          }
        }
      });
    }
  }

  fRight() {
    document.getElementsByClassName('filter_menue')[0].scrollBy(50, 0);
  }

  fLeft() {
    document.getElementsByClassName('filter_menue')[0].scrollBy(-50, 0);
  }


  vertical_menu() {
    var self = this;
    self._dataservice.get_vertical_menu().subscribe((res: any) => {
      self.vertical_menus = res.data ? res.data : [];
    })
  }

  getProductList(verticalId) {
    console.log("vertical id", verticalId)
    this.loading = true;
    var self = this;
    this.isShowMore = false;
    this.isNotShownMore = true;
    this.selectedGender = 0;
    this.productList = [];
    this.productListPart = [];
    this.showMasonry = false
    self._dataservice.get_productsById(verticalId).pipe(retry(0)).subscribe((res: any) => {
      if (res.data.products) {
        res.data.products.filter((ress: any) => {
          this.wish_catagory_id = ress?.wish_category_id
        })
        this.loadedAll = false;
        this.productList = res.data.products;
        this.productListPart = this.productList.slice(0, this.productList.length);
        // this.showMasonry =true;
        if ((this.productListPart.length) <= 8) {
          this.isNotShownMore = false;
        }
        // this.masonry.reloadItems();
        console.log("Initial product list", this.productListPart)
        this.loading = false;
        this.showMasonry = true
      } else {
        this.productListPart = [];
        this.productList = []
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = false
      }
    });
    //   // this.selectedSubItemName = "";
    //   this.loading = true;
    //   var self = this;
    //   this.isShowMore = false;
    //   this.isNotShownMore = true;
    //   this.selectedGender = 0;
    //   this.productList = [];
    //   this.productListPart = [];
    //   self._dataservice.get_productsById(verticalId).pipe(retry(0)).subscribe((res: any) => {
    //     if (res.data.products) {
    //       res.data.products.filter((ress: any) => {
    //         this.wish_catagory_id = ress?.wish_category_id
    //       })
    //       this.loadedAll = false;
    //       this.productList = res.data.products;
    //       this.productListPart = this.productList.slice(0,this.productList.length);
    //  if(this.productListPart.length <= 8)
    //  {
    //   this.isNotShownMore = false;
    //  }
    //  console.log("page value",this.p,this.productListPart.length);



    //       // this.masonry.reloadItems();

    //       this.loading = false;
    //     } else {
    //       this.productListPart = [];
    //       this.productList = []
    //       // this.masonry.reloadItems();
    //       this.loading = false;
    //     }
    //   });
    this.getProductDetailList();
  }

  loadMoreProd(list) {
    this.showMasonry = false;
    this.p = 1;
    this.productListPart = []

    this.productList = []
    console.log("show more", list)
    // if(this.tokenService._get_token()==null) {
    //   $('#myModal').modal('show');
    // }
    console.log("productListPart", this.productListPart)
    console.log("vertical id", this.vertical_id);
    this.productList = list;
    // this.productList = this.productList.concat(list)

    console.log("show productListPart", this.productList, list)
    let id = this.vertical_id;
    // this.perPage = this.productListPart?.length;
    this.perPage = this.productList?.length;
    this.productListPart = list.slice(0, list.length);
    this.isShowMore = true;
    this.isNotShownMore = false;
    this.showMasonry = true;
    localStorage.setItem("showmore", 'true')
  }

  // //console.log("count" , pdlcount)
  // let n = pdlcount + 8;
  // this.productListPart = this.productList.slice(0, n);
  // if (this.productList.length === this.productListPart.length) {
  //   this.loadedAll = true;
  //   this.isShowMore = true;
  // }
  // }

  productTypeCheck(type) {
    if (type == productType.subscriber + 1) {
      return "Type 1";
    } else if (type == productType.package + 1) {
      return "Type 2";
    } else if (type == productType.media + 1) {
      return "Type 3";
    }
  }

  onSubCategorySelect(val: any, type?) {
    // this.showMasonry=true;
    console.log("on sub category selected value", val);
    console.log("filterAttributeArray", this.filterAttributeArray);
    if (this.labelOptions.length) {
      this.labelOptions = []
    }
    if (this.labelIds.length) {
      this.labelIds = []
    }
    // if(type !=='current') {
    //   this.filterAttributeArray = [];
    // }
    this.selectedSubItem = val;
    this.selectedSubItemName = val.name;
    // console.log("sub val", val);
    this.subcategoryId = val.id
    this.loading = true;
    this.valueObj = {};
    this.idObj = {};
    this.resultObj = {};
    this.filterAttributeArray = [];
    console.log("subcategory resultobj", this.resultObj);



    let data: any = {
      "vertical_id": val?.vertical_id,
      "sub_cat_id": this.subcategoryId
    }
    if (this.usermail != null) {
      data['email'] = this.usermail;
    }
    // if(this.labelIds.length>0){
    //   data.label_id=this.labelIds
    // }
    // if(this.labelOptions.length>0){
    //   data.label_option=this.labelOptions
    // }

    if (type !== 'back') {

      localStorage.setItem('selected-sub-category', JSON.stringify(val));

      // if(type !== 'another') {

      //   localStorage.removeItem('selected-attribute-array');

      // }
    }

    this.productListPart = [];
    this.productList = [];
    this.showMasonry = false;
    this._dataservice.getFilteredDropdownData(data).subscribe((res: any) => {
      console.log("sub category response", res);
      if (res['status'] === "Failed") {
        this.perPage = 8;
        // console.log("enter wrong");
        this.productListPart = [];
        this.productList = [];
        // this.masonry.reloadItems();
        this.loading = false;
        this.toastr.error(res.message);
        this.showMasonry = false;
      } else {
        if (res?.data?.products) {
          console.log("filter type on back", type);
          if (type !== 'back') {
            this.toastr.success('Filter Product Successfully');
          }

          this.perPage = 8;
          this.loadedAll = false;
          this.productList = res.data.products;
          const productid = this.productList.map(item => item.id);

          let data: any = {
            "vertical_id": val?.vertical_id,
            "sub_cat_id": this.subcategoryId,
            "product_id": productid
          }
          this.attributeDropDownTest(data)

          this.changePage(1);
          console.log("product list in on sub", this.productList)
          this.productListPart = this.productList.slice(0, this.productList.length);
          // this.masonry.reloadItems();
          this.changeDetect.detectChanges();
          console.log("on sub category productListPart", this.productListPart, this.productListPart.length);
          this.loading = false;
          this.showMasonry = true;
          if ((this.productListPart.length) <= 8) {
            console.log("held");

            this.isNotShownMore = false;
          }
          else {
            console.log("greater");
            this.isNotShownMore = true;
          }
        }
      }

    });



    // // this.isShowSubCat=false;
    // // this.selectedSubItemName = "";
    // // this.selectedSubItem = {};
    // // this.subcategoryId = "";
    // // this.productListPart = [];
    // // this.productList = [];
    // // this.subCatagoryListArray = [];
    // console.log("sub category selected value", val);
    // if (this.labelOptions.length) {
    //   this.labelOptions = []
    // }
    // if (this.labelIds.length) {
    //   this.labelIds = []
    // }
    // // this.filterAttributeArray = [];
    // this.selectedSubItem = val;
    // this.selectedSubItemName = val.name;
    // console.log("sub val", val);
    // this.subcategoryId = val.id
    // this.loading = true;
    // // this.valueObj = {};
    // this.idObj = {};
    // this.resultObj = {};
    // this.filterAttributeArray=[];
    // this.labelOptions=[];
    // this.labelIds=[];
    // this.valueObj={};

    // let data: any = {
    //   "vertical_id": val?.vertical_id,
    //   "sub_cat_id": this.subcategoryId
    // }

    // // if(this.labelIds.length>0){
    // //   data.label_id=this.labelIds
    // // }
    // // if(this.labelOptions.length>0){
    // //   data.label_option=this.labelOptions
    // // }

    // if (type !== 'back') {

    //   localStorage.setItem('selected-sub-category', JSON.stringify(val));

    //   localStorage.removeItem('selected-attribute-array');

    // }

    // this.productListPart = [];
    // this.productList = [];
    // this._dataservice.getFilteredDropdownData(data).subscribe((res: any) => {
    //   console.log("response", res);
    //   if (res['status'] === "Failed") {
    //     console.log("enter wrong");
    //     this.productListPart = [];
    //     this.productList = [];
    //     // this.masonry.reloadItems();
    //     console.log("product list part", this.productListPart);
    //     console.log("product list", this.productList);
    //     this.loading = false;
    //     this.toastr.error(res.message);
    //   } else {
    //     if (res?.data?.products) {
    //       this.toastr.success('Filter Product Successfully');
    //       this.loadedAll = false;
    //       this.productList = res.data.products;
    //       this.productListPart = this.productList.slice(0,this.productList.length);
    //       // this.masonry.reloadItems();
    //       console.log("productListPart sub", this.productListPart);
    //       console.log("product list length",this.productListPart.length);
    //       if(this.productListPart.length <= 8)
    //       {
    //        this.isNotShownMore = false;
    //       }
    //       this.loading = false;


    //     }
    //   }
    // });
  }

  onGnderSelect(val: any) {
    console.log("gender select:", val);
    // return false;
    this.loading = true;
    var req = {
      "vertical_id": this.vertical_id,
      "gender": val.id
    }
    this.productList = [];
    this.productListPart = [];
    this.showMasonry = false
    this._dataservice.getGenderCatagoryList(req).subscribe((res: any) => {
      if (res?.data?.products) {
        this.loadedAll = false;
        this.productList = res?.data.products;
        this.productListPart = this.productList.slice(0, this.productList.length);
        if ((this.productListPart.length) <= 8) {
          this.isNotShownMore = false;

        }
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = true;
      } else {
        this.productListPart = [];
        this.productList = [];
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = false;
      }

    })
  }

  onSizeSelect(val) {
    console.log("size select", val);
    this.loading = true;
    this.sizeVal = val
    var json = {
      "vertical_id": this.vertical_id,
      "size": val,
      "material": ""
    }
    this.productList = [];
    this.productListPart = [];
    this.showMasonry = false
    this._dataservice.filterById(json).subscribe((res: any) => {
      if (res?.data?.products) {
        this.loadedAll = false;
        this.productList = res?.data.products;
        this.productListPart = this.productList.slice(0, this.productList.length);
        this.showMasonry = true;
        if ((this.productListPart.length) <= 8) {
          this.isNotShownMore = false;

        }
        // this.masonry.reloadItems();
        this.loading = false;


      }
      else {
        this.productListPart = [];
        this.productList = [];
        // this.masonry.reloadItems();
        this.loading = false;
        this.showMasonry = false;
      }

    })
  }
  onMaterialSelect(val) {
    console.log("material select", val);
    this.loading = true;
    this.materialVal = val;
    var json = {
      "vertical_id": this.vertical_id,
      "size": this.sizeVal ? this.sizeVal : "",
      "material": this.materialVal ? this.materialVal : ""
    }
    this.productList = [];
    this.productListPart = [];
    this._dataservice.filterById(json).subscribe((res: any) => {
      if (res?.data?.products) {
        this.loadedAll = false;
        this.productList = res?.data.products;
        this.productListPart = this.productList.slice(0, this.productList.length);
        this.showMasonry = true;
        if ((this.productListPart.length) <= 8) {
          this.isNotShownMore = false;
        }
        // this.masonry.reloadItems();
        this.loading = false;
      }
      else {
        this.productListPart = [];
        this.productList = [];
        // this.masonry.reloadItems();
        this.loading = true;
        this.showMasonry = false;
      }
    })
  }

  ngOnDestroy() {
    // this.filterAttributeArray = [];
    // this.store.cached = this.store.cached.splice(0,this.store.cached.length);
    localStorage.removeItem('vertical_id')
    localStorage.removeItem('catagoryName');
    localStorage.removeItem('category-list');
    localStorage.removeItem('set-result-object');
    localStorage.removeItem('sub-category');
    localStorage.removeItem('attr-options');
    if (this.subscription) {
      this.subscription.unsubscribe();
      this._dataservice.changeMessage('default message');
    }
    // this.getBackSubAttr$.unsubscribe();
    //  this._dataservice.getGalleryDataBackService({});
  }

  // Preserve original property order
  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  }

  // Order by ascending property value
  valueAscOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.value.localeCompare(b.value);
  }

  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  }

  OnAddToCart(data) {
    // if (this.isloggedIn) {
    //   $('#exampleModalCenter').modal('show');

    // }
    console.log("data", data)
    this.popupimage = data.main_image
    console.log("popup image", this.popupimage)
    this.vertical_id = data.id
    $('#exampleModalCenter').modal('show')
    this.savePart = data.id;
    this.categorycreateList(this.savePart);
  }
  removepopup() {
    $('#exampleModalCenter').modal('hide')
  }
  categoryCreate() {
    console.log("upload imng", this.uploadImg)

    var CCData: any = {
      'catagory_name': this.categoryName,
      'user_id': this.userid,
      'product_id': this.savePart,
    }
    console.log('ccdata', CCData)
    // if (this.uploadImg) {

    //   CCData.wish_img = this.uploadImg;
    // }
    if (!this.categoryName) {
      this.toastr.error('Enter Any Name to Add Catagory');
    }

    else {
      this._dataservice.category(CCData).subscribe((res: any) => {
        if (res.status == "Success") {
          // this.OnAddInterest();
          this.toastr.success('Wishlist category created sucessfully');
          this.categoryName = ""
          this.categorycreateList(this.savePart)
        }
        else if (res.status == "Failed") {
          console.log(res)
          this.toastr.error("Wishlist Category Name Already Exists")
        }
      }, err => {
      })
    }
  }
  categoryEdit() {
    console.log("leather data", this.leathers)
    var CCData: any = {
      'categoryName': this.categoryName,
      'user_id': this.userid,
      'wish_catagory_id': this.wish_catagory_id,
    }
    console.log('ccdata', CCData)
    if (!this.categoryName) {
      this.toastr.error('Enter Any Name to Add Catagory');
    }

    else {
      this._dataservice.categoryEditName(CCData).subscribe((res: any) => {
        if (res.status == "Success") {
          // this.OnAddInterest();
          this.toastr.success('Wishlist category name chaned sucessfully');
          this.categoryName = ""
          this.iscategorynameedit = false;
          this.categorycreateList(this.savePart)
          console.log('edit', this.savePart)
        }
        else if (res.status == "Failed") {
          console.log(res)
          this.toastr.error("Wishlist Category Name Already Exists")
        }
      }, err => {
      })
    }
  }
  addCategory(data) {
    // console.log("tets", data)
    this.wish_catagory_id = data?.wish_catagory_id;
    if (data?.id) {
      this.savePart = data?.id
    } else {
      this.savePart = this.savePart
    }

    var SendData =
    {
      'user_id': this.userid,
      'product_id': this.savePart,
      'wish_catagory_id': this.wish_catagory_id
    }
    console.log("addd interesrt", SendData);
    // return false;
    this._dataservice.updateWishList(SendData).subscribe((res: any) => {
      if (res) {
        this.getProductDetailList();
        this.getProductList(this.selectedValue);
        console.log("wish list id", this.selectedValue, "savepart", this.savePart)
        this.categorycreateList(this.savePart)

      }
      this.toastr.success(res.message, 'Success');
    })

  }
  editproductname(data) {
    this.iscategorynameedit = true;
    console.log("leat ", data)
    this.categoryName = data.catagory_name;
    this.wish_catagory_id = data?.wish_catagory_id;
    if (data?.id) {
      this.savePart = data?.id
    } else {
      this.savePart = this.savePart
    }
  }
  deleteleatsetting(data) {
    this.deleteleatset = data.wish_catagory_id;
  }
  delectproductlist() {
    console.log("data", this.vertical_id, this.selectedValue)
    var CCData: any = {
      'product_id': this.vertical_id,
      'wish_catagory_id': this.deleteleatset
    }

    this._dataservice.categoryDelete(CCData).subscribe((res: any) => {
      this.toastr.success('Wishlist category deleted sucessfully');
      this.categorycreateList(this.savePart)
      this.getProductList(this.selectedValue);
      console.log("delete", this.savePart)
    }, err => {
    })


  }
  categorycreateList(id) {
    this._dataservice.categorylist(id).subscribe((res: any) => {
      console.log("leather data", res)
      if (res?.data) {
        this.leathers = res.data;
        // console.log(this.leathers)
        var data;
        data = this.leathers.find(element => element.is_product_added == true);

        if (data) {
          this.isAdded = true
        }
        else {
          this.isAdded = false
        }
      }

      //console.log("hello", res)
    }, err => {

    })
  }

  getProductDetailList() {
    var self = this;
    let productSubImgList: any[];
    self.product_img_list = [];
    //console.log("this.vertical_id ", this.vertical_id);
    self._dataservice.get_productsDetailsById(this.vertical_id).subscribe((res: any) => {
      if (res && res?.data) {
        self.productDetails = res?.data;
        console.log("self.productDetails ", self.productDetails)
        this.product_name = this.productDetails.name;

        this.mydatas = res.data.attributes
        console.log('**********', this.mydatas)

        productSubImgList = self.productDetails?.sub_img;
        this.sub_cat_id = self.productDetails?.sub_cat_id;
        this.vertical_Name = self.productDetails?.vertical_name;
        this.cate_id = self.productDetails?.vertical_id;

        for (let img of productSubImgList) {
          self.product_img_list.push(img);
        }
        this.imgSrc = this.product_img_list[0];
        // alert(this.mainImg)
      }


      if (this.sub_cat_id) {

        this.getRelatedProducts();
      }


    })
  };
  getRelatedProducts() {

    var reqObj = {
      "sub_cat_id": this.sub_cat_id
    }
    this.relatedProductList = [];
    var dub_data = []
    this._dataservice.getRelatedProducts(reqObj).subscribe((res: any) => {
      console.log("reeee", res?.data?.products)
      if (res) {
        this.changeDetect.detectChanges()
        if (res?.data?.products !== undefined) {
          this.relatedProductList = res?.data?.products.filter(data => {
            return data.id !== this.productDetails.id
          })
          this.relatedProductList.map(ele => {
            return ele.id = JSON.stringify(ele.id)
          })
          if (this.relatedProductList !== undefined) {
            this.relatedProductList = this.relatedProductList
          }
        }
      } else if (res.message == 'No RelatedProduct list') {
        this.relatedProductList = []
      }
    })
  }

  productlistpage(id, name, Vertical_name, categoryname) {
    console.log("selectedSubItemName", this.selectedSubItemName);

    console.log("route", id, name, Vertical_name, categoryname[0]);
    let newcategoryname;
    if (this.selectedSubItemName) {
      newcategoryname = this.selectedSubItemName;
    }
    else {
      newcategoryname = categoryname[0];
    }
    this.router.navigate(['/categories/details', id, name, Vertical_name, newcategoryname])

    // let subcate = JSON.parse(localStorage.getItem('selected-sub-category'));
    // let subatri = JSON.parse(localStorage.getItem('selected-attribute-array'));
    // console.log("subcate",subcate,subatri)
    // if(subcate !== null || subatri !== null ){ 
    //   console.log("navigate in active")
    //   this.router.navigate(['/categories/details'],{state:{'id':id,'name':name,'vertical_name':Vertical_name,'subcate':subcate,'subatri':subatri }})
    //   this.router.navigate(['/categories/details',id,name,Vertical_name],{state:{'subcate':subcate,'subatri':subatri }})
    // }
    // else{
    //   console.log("navigate else part")
    //   this.router.navigate(['/categories/details',id,name,Vertical_name ])
    // }

  }


  attributeDropDownTest(obj) {
    console.log('Veriticval', obj)
    if (this.typeOfRoute !== 'another') {
      console.log("Test")
      this.filterAttributeArray = [];
      localStorage.removeItem('selected-attribute-array');
    }
    this.catagoryDropDownMain = [];
    this._dataservice.catagorydropdown(obj).subscribe((res: any) => {
      console.log('Respionse', res)
      if (res.data) {
        this.catagoryDropDownMain = res.data;
        this.catagoryDropDownMain = this.catagoryDropDownMain.map((list, i) => {
          return {
            ...list,
            "organizedId": i
          }
        })
      }


    })

    //   this.catagoryDropDownMain = [];
    //   if(this.typeOfRoute !== 'another') {
    //     this.filterAttributeArray = [];
    //     localStorage.removeItem('selected-attribute-array');
    //   }
    // this._dataservice.catagorydropdown(obj).subscribe((res: any) => {
    //   console.log("attribute dropdowns response:",res);
    //   if (res.data) {
    //     this.catagoryDropDownMain = res.data;
    //     this.catagoryDropDownMain = this.catagoryDropDownMain.map((list,i)=>{
    //       return{
    //         ...list,
    //         "organizedId": i
    //       }
    //     })
    //     console.log("attribute dropdown list:", this.catagoryDropDownMain)
    //     this.valueObj = {};
    //     this.idObj = {};

    //     this.catagoryDropDownMain.forEach((item, idx) => {
    //     console.log("yye", item)
    //       this.valueObj[item.label.name] = ""
    //       this.idObj[item.label.id] = ""
    //       this.valueObj = {};
    //       this.idObj = {};
    //       console.log("Value",this.valueObj)
    //     })
    //   }
    // })
  }

}

export enum productType {
  subscriber,
  package,
  media,
  visualization,
  dataset,
  Tool,
  snap,
  page
}


// export interface RecordData {
//   Vertical_name: string;
//   description: string;
//   details: any;
//   id: number;
//   is_favorite: boolean;
//   main_image: string;
//   name: string;
//   product_type: number;
//   size: string;
//   sub_image: Array<any>;
//   vertical_id: number;
//   video: number
// }

// interface RecordStore {
//   cached?: RecordData[];
//   refined?: RecordData[];
//   stamp?: Date;
// }

function currentp(arg0: string, p: any, arg2: string, currentp: any) {
  throw new Error('Function not implemented.');
}


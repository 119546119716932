import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { MatCarouselModule } from '@ngmodule/material-carousel';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BreadcrumbModule } from 'angular-crumbs';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { ValidateEqualModule } from 'ng-validate-equal';
import { NgxCarouselModule } from "ngx-light-carousel";
import { NgxMasonryModule } from 'ngx-masonry';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgwWowModule } from 'ngx-wow';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { HiddenLayoutComponent } from './layout/hidden-layout/hidden-layout.component';
import { ShowLayoutComponent } from './layout/show-layout/show-layout.component';
import { AuthGuardService } from './middleware/auth_guard';
import { TokenAuthInterceptor } from './middleware/token-auth.interceptor';
import { DirtyCheckGuard } from './quards/dirty-check.guard';
import { RegistrationComponent } from './registration/registration.component';
import { SharedModule } from "./shared/shared.module";
import { CheckPasswordDirective } from './_helper/check-password.directive';
import { MustMatchDirective } from './_helper/must-match.directive';
import { ModalComponent } from './modal/modal/modal.component';
import { PrivacyTermsViewComponent } from './layout/footer/privacy-terms-view/privacy-terms-view.component';
import { NewregisterComponent } from './layout/newregister/newregister.component';
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HiddenLayoutComponent,
    ShowLayoutComponent,
    HomeComponent,
    RegistrationComponent,
    MustMatchDirective,
    CheckPasswordDirective,
    HeaderComponent,
    PrivacyTermsViewComponent,
    NewregisterComponent,
  ],
  imports: [
    BrowserModule,
    BreadcrumbModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxCarouselModule,
    ToastrModule.forRoot( {timeOut: 2500}), 
    AppRoutingModule,
    NgwWowModule,
    NgxSpinnerModule,
    // NgMasonryGridModule,
    NgxMasonryModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    NgbNavModule,
    ClickOutsideModule,
    ValidateEqualModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatCarouselModule.forRoot(),
    IvyCarouselModule,
    CarouselModule,
    SharedModule
  ],
  providers: [

    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenAuthInterceptor,
      multi: true,

    },
    AuthGuardService,
    CookieService,
    DirtyCheckGuard,
    CanDeactivateGuard
  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { DataService } from "../services/data.service";
import { TokenService } from "../services/token.service";
import { FormGroup } from '@angular/forms';
import { AlertPromise } from 'selenium-webdriver';
declare var $;
@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css"],
})
export class RegistrationComponent implements OnInit {
  @ViewChild("regForm") regForm: FormGroup;
  loginData: any = {};
  allowedRegExp: any;
  show: boolean = true;
  model: any = {};
  userdata: any = { industry_id: "", register_type: "0" };
  industryList: any = [];
  fieldTextType: boolean;
  passType: boolean = false;
  cPass_type: boolean = false;
  selected = 1;
  clientTypeList: any;
  homeData: any;
  constructor(
    private _dataservice: DataService,
    private toastr: ToastrService,
    private router: Router,
    private token: TokenService
  ) {}
  isReadOnly = true;
  show_eye_confirm: boolean = false;
  show_eye: boolean = false;
  ngOnInit(): void {
    this.getIndustryList();
    this.getClientType();
    setTimeout(() => {
      this.isReadOnly = false;
    }, 1000);
    this.getHomeData();
  }

  onSubmit() {
    this._dataservice.login(this.loginData).subscribe((res: any) => {
      if (res.status == "Success") {
        this.token._set_data(res);
        this.toastr.success(res.message, "Success");
        setTimeout(() => {
          $("#myModal").modal("hide");
          this.router.navigateByUrl("/");
        }, 1000);
      }
    });
  }

  getHomeData() {
    var self = this;

    self._dataservice.get_homedata().subscribe((res: any) => {
      console.log("menussss", res);
      self.homeData = res.data ? res.data : [];
      console.log("home data", this.homeData);
    });
  }
  getClientType() {
    var self = this;
    self._dataservice.get_clienttype().subscribe((res: any) => {
      self.clientTypeList = res.data ? res.data : [];
      console.log("ddd", self.clientTypeList);
      // if(self.industryList.length>0)
      // {
      //   self.userdata.industry_id = self.industryList[0].id
      // }
    });
  }
  getIndustryList() {
    var self = this;
    self._dataservice.get_industries().subscribe((res: any) => {
      self.industryList = res.data ? res.data : [];
      // if(self.industryList.length>0)
      // {
      //   self.userdata.industry_id = self.industryList[0].id
      // }
    });
  }

  // toggleVal(){
  //   if(this.show_eye_confirm){
  //     this.cPass_type="password"
  //   }
  //   this.cPass_type='text'
  // }

  onSubmitRegistration() {
    var self = this;
    console.log("this.regForm.invalid", this.regForm.invalid);
    if (this.regForm.invalid) {
      this.toastr.warning("Fill all required fields", "Warning");
    } else if (this.userdata.register_type=='0') {
      this.toastr.warning("User type is required");
    } else {
      self._dataservice.registerUser(self.userdata).subscribe(
        (res: any) => {
          if (res.status == "Success") {
            this.toastr.success(res.message, "Success");
            setTimeout(() => {
              this.router.navigateByUrl("/");
            }, 1000);
          } else if (res.status == "Failed") {
            var statuses: any = [];
            if (res.message) {
              res.message.forEach((element, index) => {
                statuses.push(`* ${element}, `);
              });
            }
            if (statuses) this.toastr.error(statuses, "Failure");
          }
        },
        (err: any) => {
          this.toastr.error("Something went wrong", "Error");
        }
      );
    }
  }
  alphaNumberOnly(e) {
    // Accept only alpha numerics, not special characters
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
      return true;
    }

    e.preventDefault();
    return false;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
  _keyUp(event: any) {
(event.charCode > 64 && event.charCode < 91) ||
  (event.charCode > 96 && event.charCode < 123);
  }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SafeHtmlPipe, SafePipe } from '../_pipe/safe-html.pipe';
import { ModalComponent } from '../modal/modal/modal.component';




@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafePipe,
    ModalComponent
  ],
  imports: [
    CommonModule,
FormsModule
  ],
  exports:[FormsModule,
    ModalComponent,
  SafeHtmlPipe,SafePipe
]
})
export class SharedModule { }

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ShowLayoutComponent } from './layout/show-layout/show-layout.component';
import { HiddenLayoutComponent } from './layout/hidden-layout/hidden-layout.component';
import { HomeComponent } from './home/home.component';
import { RegistrationComponent } from './registration/registration.component';
import { NewregisterComponent } from './layout/newregister/newregister.component';

const routes: Routes = [
  {
    path: '', component: HiddenLayoutComponent,
    children: [
      {
        path: '', component: HomeComponent, pathMatch: 'full', data: { num: 1 }
      },
      {
        path: 'registration', component: RegistrationComponent, data: { num: 2 }
      },
      {
        path:'newregistration',component:NewregisterComponent
      }
    ]
  },
 
  {
    path: '', component: ShowLayoutComponent,
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)},
  // { path: '**', redirectTo: 'page-not-found' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private router: Router) { }
  _get_token(){
   // get token here
   return localStorage.getItem('token')
  }

  _get_token_type(){
    // get token here
    return localStorage.getItem('token_type')
   }

  _logout(){
    localStorage.clear();
    // logout logic goes here
  }

  _set_data(resObj: any) {
    localStorage.setItem('token', resObj.access_token);
    localStorage.setItem('token_type', resObj.token_type);
    localStorage.setItem('expires_at', resObj.expires_at);
    localStorage.setItem('name', resObj.data.name);
    localStorage.setItem('user_id', resObj.data.user_id);
    localStorage.setItem('email', resObj.data.email);
    resObj.data.profile_image? localStorage.setItem('profile_image', resObj.data.profile_image): '';
  }
}

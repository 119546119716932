import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from "@angular/common";

@Component({
  selector: 'app-hidden-layout',
  templateUrl: './hidden-layout.component.html',
  styleUrls: ['./hidden-layout.component.css']
}) 
export class HiddenLayoutComponent implements OnInit {
  showHeader: boolean = false;
  constructor(private router: Router) {
    this.router.events.subscribe((val: any) => {
      // see also 
      if (val instanceof NavigationEnd && val.url == '/') {
        this.showHeader = true;
      }else if (val instanceof NavigationEnd) {
        this.showHeader = false;
      }
    });
  }

  ngOnInit(): void {

  }

}

import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-terms-view',
  templateUrl: './privacy-terms-view.component.html',
  styleUrls: ['./privacy-terms-view.component.css']
})
export class PrivacyTermsViewComponent implements OnInit,AfterViewInit {
  public showPrivacy: boolean = false;
  public showTerms: boolean = false;
  //  @Input() homeDatas:any = {};
  //  @Input() activatePopUp:any = {};
  public homeData: any;
  public data: any;

  constructor(@Inject(MAT_DIALOG_DATA) public incomeData: any, private dialogRef: MatDialogRef<PrivacyTermsViewComponent>) {
    console.log("data received from componenet", incomeData);
    this.data = incomeData;
    
  }

  ngOnInit(): void {
    if (this.data !== undefined) {
      this.homeData = this.data?.value?.homeData;
      console.log("home data", this.homeData);
      let checkTypeOfShow = this.data?.value?.setValue;
      console.log("check type", checkTypeOfShow);
      if (checkTypeOfShow['event'] != "privacy") {
        this.showTerms = checkTypeOfShow?.type;
        this.showPrivacy = !this.showTerms;
        console.log(" terms area show temrs",this.showTerms);
        console.log("terms part show privacy",this.showPrivacy);
      } else {
        this.showPrivacy = checkTypeOfShow?.type;
        this.showTerms = !this.showPrivacy;
        console.log(" privacy part show temrs",this.showTerms);
        console.log("privacy part show privacy",this.showPrivacy);
      }
    }
  }

  ngAfterViewInit(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
  
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //     // console.log("changes on privilage",changes);
  //     this.homeData = changes?.homeDatas?.currentValue;
  //     console.log("home data",this.homeData)
  //     let checkTypeOfShow = changes?.activatePopUp?.currentValue;
  //     console.log("check show type",checkTypeOfShow);
  //     if(checkTypeOfShow?.event !== "privacy"){
  //       this.showTerms = checkTypeOfShow?.type;
  //       this.showPrivacy = !this.showTerms;
  //     } 
  // }
}
